import React from "react";
import Highlighter from "react-highlight-words";
import Tooltip from "antd/lib/tooltip";
import StatusIndicator from "../statusIndicator";
import GetColumnSearchProps from "../columnSearch";
import {formatDateTime} from "../../helpers/miscHelpers";


const ArticleIndicatorConditions = (row, entityStates, stateTextConstant, stateColorConstant) =>
    Object.entries(entityStates).map(function (state) {
        return {
            condition: row.State === state[1],
            color: stateColorConstant[state[1]],
            tooltip: stateTextConstant[state[1]]
        };
    });

export const indicatorColumn = (entityStates, stateTextConstant, stateColorConstant) => {
    return {
        key: "indicator",
        dataIndex: "Indicator",
        render: (id, row) => (
            <StatusIndicator
                conditions={ArticleIndicatorConditions(row, entityStates, stateTextConstant, stateColorConstant)}
            />
        ),
        width: 20
    };
};

export const statusColumn = (component, entities, stateField, stateTextConstant) => {
    const selectedFilters = component.state.filters.state;
    const existingStates = [];
    entities.forEach((entity) => {
        if (!existingStates.includes(entity[stateField])) {
            existingStates.push(entity[stateField]);
        }
    });
    return {
        title: "Status",
        key: "state",
        dataIndex: "State",
        filters: existingStates.map((state) => ({
            value: state,
            text: stateTextConstant[state]
        })),
        filteredValue: selectedFilters ? selectedFilters.split(",") : undefined,
        onFilter: (value, record) => record.State.toString() === value.toString(),
        render: (id, row) => (
            <div>
                {stateTextConstant[row.State]}
            </div>
        )
    };
};

export const idColumn = (component) => {
    const idFilter = component.state.filters.id;
    if (idFilter) {
        component.state.searchedColumn = "Id";
        component.state.searchText = idFilter;
    }
    return {
        title: "Id",
        key: "id",
        dataIndex: "Id",
        width: 25,
        sortDirections: ["descend", "ascend"],

        render: (Id, row) =>
            component.state.searchedColumn === "Id" ? (
                <Highlighter
                    highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
                    searchWords={[component.state.searchText]}
                    autoEscape
                    textToHighlight={row.Id.substr(row.Id.length - 5)}
                />
            ) : (
                row.Id.substr(row.Id.length - 5)
            ),
        ...GetColumnSearchProps("Id", 5, component.setSearchText, component.setSearchColumn),
        filteredValue: idFilter ? [idFilter] : undefined
    };
};

export const updatedColumn = () => {
    return {
        title: "Updated",
        key: "updated",
        dataIndex: "Updated",
        render: (id, row) => (
            <Tooltip title="Updated"> {formatDateTime(row.LastUpdated)}</Tooltip>
        ),
        sorter: (a, b) => {
            return a.LastUpdated && a.LastUpdated.localeCompare(b.LastUpdated);
        },
        sortDirections: ["descend", "ascend"]
    };
};

