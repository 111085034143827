import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Spin from "antd/lib/spin";
import "./index.css";

class Loading extends PureComponent {
  render() {
    const { text } = this.props;
    return (
      <div className="fullscreen-center">
        <Spin size="large" tip={text} />
      </div>
    );
  }
}

Loading.propTypes = {
  text: PropTypes.string,
};
Loading.defaultProps = {
  text: "Loading...",
};

export default Loading;
