import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import PropTypes from "prop-types";

import Table from "antd/lib/table";
import Modal from "antd/lib/modal";
import { SearchOutlined } from "@ant-design/icons";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import message from "antd/lib/message";

import { Link } from "react-router-dom";
import * as Util from "../../helpers/miscHelpers";
import * as constants from "../../constants";
import {
	isAdmin,
	isNarrator,
	isGuideNarrator,
	isGuideProofListener,
	isProofListener
} from "../../helpers/authHelpers";
import { connector } from "../../store";
import * as Columns from "./columns";
import EditMaterialForm from "../../forms/editMaterialForm";
import "./index.css";
import {
	getQuerystringParam,
	updateQueryString
} from "../../helpers/querystringHelpers";
import * as logger from "../../helpers/logger";
import Loading from '../loading'
import {updateDefaultGuideProofListener} from "../../store/actions";

function getColumns(users, user, component) {
	if (user.groups) {
		if (isAdmin(user)) {
			return [
				Columns.titleColumn(component),
				Columns.articlesColumn(component),
				Columns.createdColumn(),
				Columns.updatedColumn(),
				Columns.defaultNarratorColumn(users, component),
				Columns.defaultProoflistenerColumn(users, component),
				Columns.assignedPeopleColumn(users, component),
				Columns.enableDisableColumn(component),
				Columns.editColumn(component)
			];
		}
		if (isNarrator(user)) {
			return [
				Columns.titleColumn(component),
				Columns.assignedToMeColumn(user, component),
				Columns.assignedToMeForCorrectionsColumn(component)
			];
		}
		if (isGuideNarrator(user)) {
			return [
				Columns.guidesTitleColumn(component),
				Columns.assignedToMeGuidesColumn(user, component),
				Columns.assignedToMeGuidesForCorrectionsColumn(component),
				Columns.assignedToMeWithArticleContentChanged(component)
			];
		}
		if (isProofListener(user)) {
			return [
				Columns.titleColumn(component),
				Columns.assignedToMeColumn(user, component)
			];
		}
		if (isGuideProofListener(user)) {
			return [
				Columns.guidesTitleColumn(component),
				Columns.assignedToMeGuidesColumn(user, component)
			];
		}
	}
	return null;
}

class TeachingMaterials extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalVisible: false,
			modalMaterial: {},
			filters: {
				status: Object.values(constants.ARTICLE_STATE).join(",")
			}
		};
	}

	async componentDidMount() {
		const { match, action } = this.props;

		this.publisherId = match.params.publisherId;

		await action.getUsersIfNeeded();
		await action.getPublishersIfNeeded();
		await action.getTeachingMaterialsIfNeeded(this.publisherId);

		action.updatePath(window.location.pathname);

		this.parseQueryString();
		window.addEventListener("popstate", this.parseQueryString);
	}

	componentWillUnmount() {
		window.removeEventListener("popstate", this.parseQueryString);
	}

	parseQueryString = () => {
		const { filters, page } = this.state;
		const newFilters = {};
		["articles", "title", 'DefaultNarratorId', 'DefaultProofListener'].forEach((p) => {
			newFilters[p] = getQuerystringParam("filter-" + p);
		});

		const newPage = parseInt(getQuerystringParam("page", 1));
		if (
			JSON.stringify(filters) !== JSON.stringify(newFilters) ||
			page !== newPage
		) {
			this.setState({
				filters: newFilters,
				page: newPage
			});
			updateQueryString(`/publishers/${this.publisherId}`, newPage, newFilters);
		}
	};

	onStateBtnClick = (props, row, type) => {
		if (type === "disable") {
			props.action
				.setMaterialDisableState(props.match.params.publisherId, row.Id)
				.then(() => {
					message.success("All articles have been disabled successfully");
					return null;
				})
				.catch((err) => {
					logger.error(err, "Failed to disable material");
				});
		}
		if (type === "enable") {
			props.action
				.setMaterialEnableState(props.match.params.publisherId, row.Id)
				.then(() => {
					message.success("All articles have been enabled successfully");
					return null;
				})
				.catch((err) => {
					logger.error(err, "Failed to enable material");
				});
		}
	};

	onPageOrFilteredChange = (pagination, argFilters) => {
		const { filters, page } = this.state;
		let newFilters = {};
		const newPage = parseInt(
			Util.isEmpty(pagination?.current) ? page : pagination?.current || 1
		);

		if (Util.isEmpty(argFilters)) {
			newFilters = filters;
		} else {
			Object.keys(argFilters)
				.filter((p) => !Util.isEmpty(argFilters[p]))
				.forEach((p) => {
					newFilters[p] =
						argFilters[p].constructor === Array
							? argFilters[p].join(",")
							: argFilters[p];
				});
		}
		this.setState({
			filters: newFilters,
			page: newPage
		});
		updateQueryString(`/publishers/${this.publisherId}`, newPage, newFilters);
	};

	handleModalSetDefaultNarrator = async (userId, overwrite = false) => {
		const { match, action } = this.props;
		const { modalMaterial } = this.state;

		try {
			await action.updateDefaultNarrator(
				match.params.publisherId,
				modalMaterial.Id,
				userId,
				overwrite
			);
			message.success("Narrator assigned successfully");
		} catch (err) {
			logger.error(err, "Failed to assign default narrator");
		}
	};

	handleModalSetDefaultGuideNarrator = async (userId, language, overwrite = false) => {
		const {match, action} = this.props;
		const {modalMaterial} = this.state;

		try {
			await action.updateDefaultGuideNarrator(
				match.params.publisherId,
				modalMaterial.Id,
				language,
				userId,
				overwrite
			);
			message.success("Guides language narrator assigned successfully");
		} catch (err) {
			logger.error(err, "Failed to assign default guides narrator");
		}
	};

	handleModalSetDefaultGuideProofListener = async (userId, language, overwrite = false) => {
		const {match, action} = this.props;
		const {modalMaterial} = this.state;

		try {
			await action.updateDefaultGuideProofListener(
				match.params.publisherId,
				modalMaterial.Id,
				language,
				userId,
				overwrite
			);
			message.success("Guides language proof listener assigned successfully");
		} catch (err) {
			logger.error(err, "Failed to assign default guides proof listener");
		}
	};

	handleModalSetDefaultProofListener = async (userId, overwrite = false) => {
		const { match, action } = this.props;
		const { modalMaterial } = this.state;

		try {
			await action.updateDefaultProofListener(
				match.params.publisherId,
				modalMaterial.Id,
				userId,
				overwrite
			);
			message.success("Proof listener assigned successfully");
		} catch (err) {
			logger.error(err, "Failed to assign proof listener");
		}
	};

	showModal = (material) => {
		this.setState({
			modalMaterial: material,
			modalVisible: true
		});
	};

	getColumnSearchProps = (dataIndex, props, path) => {
		const { searchText } = this.state;
		return {
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters
			}) => (
				<div className="custom-filter-dropdown">
					<Input
						ref={(node) => {
							this.searchInput = node;
						}}
						placeholder={`Search ${dataIndex}`}
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
					<Button
						onClick={() => this.handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
				</div>
			),
			filterIcon: (filtered) => (
				<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
			),
			onFilter: (value, record) =>
				record[dataIndex]
					.toString()
					.toLowerCase()
					.includes(value.toLowerCase()),
			onFilterDropdownVisibleChange: (visible) => {
				if (visible) {
					setTimeout(() => this.searchInput.select());
				}
			},
			render: (text, row) => (
				<Link
					href={path.replace('{id}', row.Id)}
					to={path.replace('{id}', row.Id)}
				>
					<Highlighter
						highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
						searchWords={[searchText]}
						autoEscape
						textToHighlight={text.toString()}
					/>
				</Link>
			)
		};
	};

	handleSearch = (selectedKeys, confirm) => {
		confirm();
		this.setState({ searchText: selectedKeys[0] });
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: "" });
	};

	_showGuidesForPublisher = () => {
		const {publishers, match} = this.props;
		const publisher = publishers?.find(
			(p) => p.Id === match.params.publisherId
		) || {GuidesEnabled: false};

		return publisher.GuidesEnabled;
	}

	render() {
		const { isLoading, user, users, publishers, match } = this.props;
		const { modalMaterial, modalVisible, page } = this.state;

		const publisher = publishers?.find(
			(p) => p.Id === match.params.publisherId
		) || { TeachingMaterials: [] };

		if ( isLoading.reDetectSentences) {
			return <Loading />;
		}

		return (
			<div className="teaching-materials">
				<Table
					rowKey="Id"
					columns={getColumns(users, user, this)}
					dataSource={publisher.TeachingMaterials}
					onChange={this.onPageOrFilteredChange}
					pagination={{
						...{
							defaultCurrent: parseInt(getQuerystringParam("page", 1)),
							current: page
						}
					}}
					loading={
						isLoading.teachingMaterials ||
						isLoading.publishers ||
						isLoading.users
					}
				/>
				<Modal
					title={modalMaterial ? modalMaterial.Title : "Select a material"}
					visible={modalVisible}
					footer=""
					onCancel={() => {
						this.setState({
							modalVisible: false
						});
					}}
				>
					<EditMaterialForm
						users={users}
						modalMaterial={modalMaterial}
						onSetDefaultNarrator={this.handleModalSetDefaultNarrator}
						onSetDefaultProofListener={this.handleModalSetDefaultProofListener}
						onSetDefaultGuideNarrator={this.handleModalSetDefaultGuideNarrator}
						onSetDefaultGuideProofListener={this.handleModalSetDefaultGuideProofListener}
						showGuidesForPublisher={this._showGuidesForPublisher()}
					/>
				</Modal>
			</div>
		);
	}
}

TeachingMaterials.propTypes = {
	isLoading: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	users: PropTypes.arrayOf(PropTypes.object),
	match: PropTypes.object.isRequired,
	publishers: PropTypes.arrayOf(PropTypes.object),
	action: PropTypes.shape({
		getUsersIfNeeded: PropTypes.func.isRequired,
		getPublishersIfNeeded: PropTypes.func.isRequired,
		getTeachingMaterialsIfNeeded: PropTypes.func.isRequired,
		updatePath: PropTypes.func.isRequired,
		updateDefaultNarrator: PropTypes.func.isRequired,
		updateDefaultGuideNarrator: PropTypes.func.isRequired,
		updateDefaultGuideProofListener: PropTypes.func.isRequired,
		updateDefaultProofListener: PropTypes.func.isRequired
	}).isRequired
};
TeachingMaterials.defaultProps = {
	publishers: [],
	users: []
};

export default connector(TeachingMaterials, (props) => ({
	user: props.user,
	publishers: props.publishers,
	isLoading: props.isLoading,
	users: props.users,
	path: props.path,
	action: {
		getUser: props.action.getUser,
		updatePath: props.action.updatePath,
		getUsersIfNeeded: props.action.getUsersIfNeeded,
		getPublishersIfNeeded: props.action.getPublishersIfNeeded,
		getTeachingMaterialsIfNeeded: props.action.getTeachingMaterialsIfNeeded,
		updateDefaultNarrator: props.action.updateDefaultNarrator,
		updateDefaultGuideNarrator: props.action.updateDefaultGuideNarrator,
		updateDefaultGuideProofListener: props.action.updateDefaultGuideProofListener,
		updateDefaultProofListener: props.action.updateDefaultProofListener,
		setMaterialEnableState: props.action.setMaterialEnableState,
		setMaterialDisableState: props.action.setMaterialDisableState
	}
}));
