import React from "react";

export default SubscribeComponent =>
	class extends React.Component {
		constructor() {
			super();
			this.subs = {};
		}

		componentWillUnmount() {
			Object.keys(this.subs).map(key => this.subs[key].stop());
		}

		subscribe(name, ...args) {
			if (this.subs[name]) this.subs[name].stop();
		}

		render() {
			return (
  <SubscribeComponent
    {...this.props}
					// eslint-disable-next-line react/jsx-no-bind
    subscribe={this.subscribe.bind(this)}
  />
			);
		}
	};
