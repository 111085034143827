/* eslint-disable react/no-unused-state */
import React, { Component } from "react";
import PropTypes from "prop-types";
import Table from "antd/lib/table";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import { Popconfirm, Tooltip } from "antd";
import {
	RollbackOutlined,
	RedoOutlined,
	UnorderedListOutlined
} from "@ant-design/icons";
import { connector } from "../../store";
import {
	isAdmin,
	isNarrator,
	isProofListener
} from "../../helpers/authHelpers";
import {
	getQuerystringParam,
	updateQueryString
} from "../../helpers/querystringHelpers";
import * as Util from "../../helpers/miscHelpers";
import * as constants from "../../constants";

import ArticleInfoForm from "../../forms/articleInfoForm";
import * as Columns from "./columns";
import * as CommonColumns from "../commonColumns";
import * as logger from "../../helpers/logger";
import { formatDateTime } from "../../helpers/miscHelpers";

import "./index.css";

function getColumns(user, users, articles, component) {
	if (user.groups) {
		if (isAdmin(user)) {
			return [
				CommonColumns.indicatorColumn(constants.ARTICLE_STATE, constants.ARTICLE_STATE_TEXT, constants.ARTICLE_STATE_COLORS),
				CommonColumns.statusColumn(component, articles,'_state', constants.ARTICLE_STATE_TEXT),
				Columns.orderColumn(component),
				CommonColumns.idColumn(component),
				Columns.titleColumn(component),
				Columns.enableGuides(component),
				CommonColumns.updatedColumn(),
				Columns.selectNarratorColumn(users, articles, component),
				Columns.selectProofListenerColumn(users, articles, component),
				Columns.enableDisableColumn(component),
				Columns.linkColumn(component)
			];
		}
		if (isNarrator(user)) {
			return [
				CommonColumns.indicatorColumn(constants.ARTICLE_STATE, constants.ARTICLE_STATE_TEXT, constants.ARTICLE_STATE_COLORS),
				CommonColumns.statusColumn(component, articles,'_state', constants.ARTICLE_STATE_TEXT),
				Columns.orderColumn(component),
				Columns.titleColumn(component),
				CommonColumns.updatedColumn(),
				Columns.enableDisableColumn(component),
				Columns.linkColumn(component)
			];
		}
		if (isProofListener(user)) {
			return [
				CommonColumns.indicatorColumn(constants.ARTICLE_STATE, constants.ARTICLE_STATE_TEXT, constants.ARTICLE_STATE_COLORS),
				CommonColumns.statusColumn(component, articles,'_state', constants.ARTICLE_STATE_TEXT),
				Columns.orderColumn(component),
				Columns.titleColumn(component),
				CommonColumns.updatedColumn(),
				Columns.enableDisableColumn(component),
				Columns.linkColumn(component)
			];
		}
	}
	return null;
}

class Articles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalArticle: {},
			filters: {
				status: null,
				id: null,
				title: null,
				narrator: null,
				proof_listener: null
			},
			searchText: "",
			searchedColumn: "",
			page: 1
		};
	}

	async componentDidMount() {
		const { match, action } = this.props;

		this.publisherId = match.params.publisherId;
		this.teachingMaterialId = match.params.teachingMaterialId;

		await action.getUsersIfNeeded();
		await action.getPublishersIfNeeded();
		await action.getTeachingMaterialsIfNeeded(this.publisherId);
		await action.getArticlesIfNeeded(this.publisherId, this.teachingMaterialId);

		action.updatePath(window.location.pathname);

		this.parseQueryString();
		window.addEventListener("popstate", this.parseQueryString);
	}

	componentWillUnmount() {
		window.removeEventListener("popstate", this.parseQueryString);
	}

	parseQueryString = () => {
		const { filters, page } = this.state;
		const newFilters = {};
		["state", "title", "id", "narrator", "proof_listener"].forEach((p) => {
			newFilters[p] = getQuerystringParam("filter-" + p);
		});

		const newPage = parseInt(getQuerystringParam("page", 1));
		if (
			JSON.stringify(filters) !== JSON.stringify(newFilters) ||
			page !== newPage
		) {
			this.setState({
				filters: newFilters,
				page: newPage
			});
			updateQueryString(this.teachingMaterialId, newPage, newFilters);
		}
	};

	setSearchText = (text) => {
		this.setState({
			searchText: text
		});
	}

	setSearchColumn = (dataIndex) => {
		this.setState({
			searchedColumn: dataIndex
		});
	}

	showModal = (row) => {
		this.setState({
			modalVisible: true,
			modalArticle: row
		});
	};

	handleModalOk = (e) => {
		this.setState({
			modalVisible: false
		});
	};

	handleModalCancel = (e) => {
		this.setState({
			modalVisible: false
		});
	};

	onStateBtnClick = (props, row) => {
		if (row.State !== constants.ARTICLE_STATE.Disabled) {
			props.action
				.setDisableState(
					props.match.params.publisherId,
					props.match.params.teachingMaterialId,
					row.Id,
					isAdmin(props.user)
				)
				.then(() => {
					if (!isAdmin(props.user)) {
						props.action.removeArticle(
							props.match.params.publisherId,
							props.match.params.teachingMaterialId,
							row.Id
						);
					}
					message.success("Article disabled successfully");
					return null;
				})
				.catch((err) => {
					logger.error(err, "Failed to disable article");
				});
		} else {
			props.action
				.setEnableState(
					props.match.params.publisherId,
					props.match.params.teachingMaterialId,
					row.Id
				)
				.then(() => {
					message.success("Article enabled successfully");
					return null;
				})
				.catch((err) => {
					logger.error(err, "Failed to enable article");
				});
		}
	};

	onEnableGuidesCheckboxClick = (props, row) => {
		if (row.GuidesEnabled) {
			props.action
				.setGuidesDisable(
					props.match.params.publisherId,
					props.match.params.teachingMaterialId,
					row.Id,
					isAdmin(props.user)
				)
				.then(() => {
					message.success("Article's guides were successfully disabled");
					return null;
				})
				.catch((err) => {
					logger.error(err, "Failed to disable article's guides");
				});
		} else {
			props.action
				.setGuidesEnable(
					props.match.params.publisherId,
					props.match.params.teachingMaterialId,
					row.Id,
					isAdmin(props.user)
				)
				.then(() => {
					message.success("Article's guides were successfully enabled");
					return null;
				})
				.catch((err) => {
					logger.error(err, "Failed to enable article's guides");
				});
		}
	};

	onAssignToNarratorBtnClick = (props, row) => {
		props.action
			.setNarrator(
				props.match.params.publisherId,
				props.match.params.teachingMaterialId,
				row.Id,
				row.NarratorId
			)
			.then(() => {
				message.success("Article sent to narrator successfully!");
				return null;
			})
			.catch((err) => {
				logger.error(err, "Failed to assign narrator");
			});
	};

	onResetBtnClick = (id) => {
		const { action, match } = this.props;
		action
			.resetArticle(
				match.params.publisherId,
				match.params.teachingMaterialId,
				id
			)
			.then((article) => {
				message.success("Article has been reset successfully");
				this.setState({
					modalVisible: true,
					modalArticle: article
				});
				return article;
			})
			.catch((err) => {
				logger.error(err, "Failed to reset article");
			});
	};

	onReProcessBtnClick = (id) => {
		const { action, match } = this.props;
		action
			.reProcessArticle(
				match.params.publisherId,
				match.params.teachingMaterialId,
				id
			)
			.then((article) => {
				message.success(
					"Article has successfully been scheduled for audio reprocessing!"
				);
				this.setState({
					modalVisible: true,
					modalArticle: article
				});
				return article;
			})
			.catch((err) => {
				logger.error(err, "Failed to schedule article for audio reprocessing.");
			});
	};

	onReDetectBtnClick = (id) => {
		const { action, match } = this.props;
		action
			.reDetectSentencesInArticle(
				match.params.publisherId,
				match.params.teachingMaterialId,
				id
			)
			.then((article) => {
				message.success(
					"Sentences in article have been re-detected, and a new version has been created"
				);
				this.setState({
					modalVisible: true,
					modalArticle: article
				});
				return article;
			})
			.catch((err) => {
				logger.error(err, "Failed to re-detect sentences in article");
			});
	};

	onUnpublishBtnClick = (props, id) => {
		props.action
			.unpublishArticle(
				props.match.params.publisherId,
				props.match.params.teachingMaterialId,
				id
			)
			.then(() => {
				message.success("Article has been unpublished successfully");

				return null;
			})
			.catch((err) => {
				logger.error(err, "Failed to unpublish article");
			});
	};

	onPublishBtnClick = (props, id) => {
		props.action
			.publishArticle(
				props.match.params.publisherId,
				props.match.params.teachingMaterialId,
				id
			)
			.then(() => {
				message.success("Article has been published successfully!");
				return null;
			})
			.catch((err) => {
				logger.error(err, "Failed to publish article");
			});
	};

	onPageOrFilteredChange = (pagination, argFilters) => {
		const { filters, page } = this.state;
		let newFilters = {};
		const newPage = parseInt(
			Util.isEmpty(pagination?.current) ? page : pagination?.current || 1
		);

		if (Util.isEmpty(argFilters)) {
			newFilters = filters;
		} else {
			Object.keys(argFilters)
				.filter((p) => !Util.isEmpty(argFilters[p]))
				.forEach((p) => {
					newFilters[p] =
						argFilters[p].constructor === Array
							? argFilters[p].join(",")
							: argFilters[p];
				});
		}
		this.setState({
			filters: newFilters,
			page: newPage
		});
		updateQueryString(this.teachingMaterialId, newPage, newFilters);
	};

	onChangeNarratorOrProofListener = (props, userId) => {
		if (props.userType === "narrator") {
			props.action
				.setNarrator(
					props.match.params.publisherId,
					props.match.params.teachingMaterialId,
					props.row.Id,
					userId
				)
				.then(() => {
					message.success("Narrator updated successfully");
					return null;
				})
				.catch((err) => {
					logger.error(err, "Failed to assign narrator");
				});
		}

		if (props.userType === "prooflistener") {
			props.action
				.setProofListener(
					props.match.params.publisherId,
					props.match.params.teachingMaterialId,
					props.row.Id,
					userId
				)
				.then(() => {
					message.success("Proof listener updated successfully");
					return null;
				})
				.catch((err) => {
					logger.error(err, "Failed to assign proof listener");
				});
		}
	};

	componentDidUpdate = () => {};

	render() {
		const { isLoading, publishers, users, user, match } = this.props;

		const { modalVisible, page, modalArticle } = this.state;

		const publisher = publishers?.find(
			(p) => p.Id === match.params.publisherId
		) || { TeachingMaterials: [] };
		const teachingMaterials = publisher.TeachingMaterials || [];
		const teachingMaterial = teachingMaterials.find(
			(t) => t.Id === match.params.teachingMaterialId
		) || { Articles: [] };

		const modalTitle = modalArticle ? modalArticle.Title : "Select article";
		const modalSubtitle = modalArticle
			? `Created: ${formatDateTime(
					modalArticle.Created
			  )}, Last updated: ${formatDateTime(modalArticle.LastUpdated)}`
			: "";

		return (
			<div className="articles">
				<Table
					rowKey="Id"
					columns={getColumns(user, users, teachingMaterial.Articles, this)}
					pagination={{
						...{
							defaultCurrent: parseInt(getQuerystringParam("page", 1)),
							current: page
						}
					}}
					dataSource={teachingMaterial.Articles}
					onChange={this.onPageOrFilteredChange}
					loading={
						isLoading.articles ||
						isLoading.teachingMaterials ||
						isLoading.publishers ||
						isLoading.users
					}
					rowClassName={(r) => {
						if (r.State === constants.ARTICLE_STATE.Published) {
							return "articles__row--published";
						}
						if (r.State === constants.ARTICLE_STATE.RecordingChanges) {
							return "articles__row--breakingchanges";
						}
						if (r.State === constants.ARTICLE_STATE.Disabled) {
							return "articles__row--disabled";
						}
					}}
				/>
				<Modal
					title={[
						<div key="modal-title">
							{modalTitle}
							<br />
							<span className="modal-subtitle">{modalSubtitle}</span>
						</div>
					]}
					visible={modalVisible}
					onOk={this.handleModalOk}
					onCancel={this.handleModalCancel}
					width={800}
					footer={
						isAdmin(user)
							? [
									<div
										key="change-state-buttons"
										className="change-state-buttons"
									>
										<Tooltip title="Reset article">
											<Popconfirm
												title="Are you sure you want to reset the article? 
						This will unassign narrator and proof listener, remove any sentence remarks, and revert state to 'New'. 
						Any recordings will remain."
												onConfirm={() => this.onResetBtnClick(modalArticle.Id)}
												okText="Yes"
												cancelText="No"
											>
												<Button key="reset" type="danger">
													<RollbackOutlined />
													Reset article
												</Button>
											</Popconfirm>
										</Tooltip>
										{(modalArticle.State ===
											constants.ARTICLE_STATE.Published ||
											modalArticle.State ===
												constants.ARTICLE_STATE.ProcessingAudio) && (
											<Tooltip title="Re-process audio for article">
												<Popconfirm
													title="Are you sure you want to re-process the audio for the article? 
															This will schedule re-processing of all audio related to this article."
													onConfirm={() =>
														this.onReProcessBtnClick(modalArticle.Id)
													}
													okText="Yes"
													cancelText="No"
												>
													<Button key="reprocess" type="danger">
														<RedoOutlined />
														Re-process audio
													</Button>
												</Popconfirm>
											</Tooltip>
										)}

										<Tooltip title="Re-detect sentences in article">
											<Popconfirm
												title="Are you sure you want to re-detect sentences in this article? This might cause breaking changes if recorded."
												onConfirm={() =>
													this.onReDetectBtnClick(modalArticle.Id)
												}
												okText="Yes"
												cancelText="No"
											>
												<Button key="redetect" type="danger">
													<UnorderedListOutlined />
													Re-detect sentences
												</Button>
											</Popconfirm>
										</Tooltip>
										<Button key="back" onClick={this.handleModalCancel}>
											Close
										</Button>
									</div>
							  ]
							: [
									<Button key="back" onClick={this.handleModalCancel}>
										Close
									</Button>
							  ]
					}
				>
					<ArticleInfoForm
						article={modalArticle}
						users={users}
						user={user}
						component={this}
					/>
				</Modal>
			</div>
		);
	}
}

Articles.propTypes = {
	isLoading: PropTypes.object.isRequired,
	users: PropTypes.arrayOf(PropTypes.object),
	user: PropTypes.object.isRequired,
	publishers: PropTypes.arrayOf(PropTypes.object),
	match: PropTypes.object.isRequired,
	action: PropTypes.shape({
		updatePath: PropTypes.func.isRequired,
		setProofListener: PropTypes.func.isRequired,
		setNarrator: PropTypes.func.isRequired,
		removeArticle: PropTypes.func.isRequired,
		setDisableState: PropTypes.func.isRequired,
		setEnableState: PropTypes.func.isRequired,
		setGuidesDisable: PropTypes.func.isRequired,
		setGuidesEnable: PropTypes.func.isRequired,
		publishArticle: PropTypes.func.isRequired,
		getUsersIfNeeded: PropTypes.func.isRequired,
		getPublishersIfNeeded: PropTypes.func.isRequired,
		getTeachingMaterialsIfNeeded: PropTypes.func.isRequired,
		getArticlesIfNeeded: PropTypes.func.isRequired,
		unpublishArticle: PropTypes.func.isRequired,
		resetArticle: PropTypes.func.isRequired,
		reProcessArticle: PropTypes.func.isRequired,
		reDetectSentencesInArticle: PropTypes.func.isRequired
	}).isRequired
};

Articles.defaultProps = {
	publishers: [],
	users: []
};

export default connector(Articles, (props) => ({
	isLoading: props.isLoading,
	publishers: props.publishers,
	user: props.user,
	users: props.users,
	path: props.path,
	action: {
		updatePath: props.action.updatePath,
		getUsersIfNeeded: props.action.getUsersIfNeeded,
		getPublishersIfNeeded: props.action.getPublishersIfNeeded,
		getTeachingMaterialsIfNeeded: props.action.getTeachingMaterialsIfNeeded,
		getArticlesIfNeeded: props.action.getArticlesIfNeeded,
		setNarrator: props.action.setNarrator,
		setProofListener: props.action.setProofListener,
		removeArticle: props.action.removeArticle,
		setDisableState: props.action.setDisableState,
		setEnableState: props.action.setEnableState,
		setGuidesDisable: props.action.setGuidesDisable,
		setGuidesEnable: props.action.setGuidesEnable,
		publishArticle: props.action.publishArticle,
		unpublishArticle: props.action.unpublishArticle,
		resetArticle: props.action.resetArticle,
		reProcessArticle: props.action.reProcessArticle,
		reDetectSentencesInArticle: props.action.reDetectSentencesInArticle
	}
}));
