import React from 'react'
import { SettingOutlined } from '@ant-design/icons'
import Tooltip from 'antd/lib/tooltip'
import Button from 'antd/lib/button'
import Popconfirm from 'antd/lib/popconfirm'

import { Link } from 'react-router-dom'
import * as constants from '../../constants'
import { findUserById, formatDateTime, getAssignedPeople } from '../../helpers/miscHelpers'
import { isNarrator, splitUsername, isGuideNarrator } from '../../helpers/authHelpers'

export const titleColumn = (component) => {
	const titleFilter = component.state.filters.title;
	if (titleFilter) {
		component.state.searchedColumn = "title";
		component.state.searchText = titleFilter;
	}
	return {
		title: "Title",
		key: "title",
		dataIndex: "Title",
		width: 300,
		sorter: (a, b) => {
			return a.Title && a.Title.localeCompare(b.Title);
		},
		sortDirections: ["descend", "ascend"],
		...component.getColumnSearchProps("Title", component.props, `${component.props.match.params.publisherId}/{id}` ),
		filteredValue: titleFilter ? [titleFilter] : undefined
	};
};

export const guidesTitleColumn = (component) => {
	const titleFilter = component.state.filters.title;
	if (titleFilter) {
		component.state.searchedColumn = "title";
		component.state.searchText = titleFilter;
	}
	return {
		title: "Title",
		key: "title",
		dataIndex: "Title",
		width: 300,
		sorter: (a, b) => {
			return a.Title && a.Title.localeCompare(b.Title);
		},
		sortDirections: ["descend", "ascend"],
		...component.getColumnSearchProps("Title", component.props, `${component.props.match.params.publisherId}/{id}/guides`),
		filteredValue: titleFilter ? [titleFilter] : undefined
	};
};
export const articlesColumn = (component) => {
	const selectedFilters = component.state.filters.articles;
	return {
		title: "Articles",
		key: "articles",
		dataIndex: "Articles",
		filters: Object.entries(constants.ARTICLE_STATE).map((state) => {
			return {
				value: state[1],
				text: constants.ARTICLE_STATE_TEXT[state[1]]
			};
		}),
		filteredValue: selectedFilters ? selectedFilters.split(",") : undefined,
		onFilter: (value, row) => {
			return (
				row.Stats.ArticleCountByState[constants.ARTICLE_STATE_NAME[value]] > 0
			);
		},
		render: (id, row) => (
			<div key={row.Id}>
				<div key={row.Id + "-total"}>
					<span>Total: {row.Stats.TotalArticles}</span>
				</div>
				{Object.entries(constants.ARTICLE_STATE).map((state) => {
					const filterUrl =
						component.props.path.replace(
							component.props.history.location.search,
							""
						) +
						"/" +
						row.Id +
						"?page=1&filter-state=" +
						state[1];
					const stateCount = row.Stats.ArticleCountByState[state[0]];
					if (stateCount > 0) {
						return (
							<div key={row.Id + "-" + state[0]} style={{ paddingTop: "3px" }}>
								<Link
									href={filterUrl}
									to={filterUrl}
									style={{
										backgroundColor: constants.ARTICLE_STATE_COLORS[state[1]],
										color: constants.ARTICLE_STATE_TEXT_COLORS[state[1]],
										padding: "3px"
									}}
								>
									{constants.ARTICLE_STATE_TEXT[state[1]]}: {stateCount}
								</Link>
							</div>
						);
					}
					return null;
				})}
				{row.Stats.ArticleCountUnassignedNarrator > 0 && (
					<div style={{ paddingTop: "3px" }}>
						<Link
							href={
								component.props.path.replace(
									component.props.history.location.search,
									""
								) +
								"/" +
								row.Id +
								"?page=1&filter-narrator=" +
								constants.GUID_EMPTY
							}
							to={
								component.props.path.replace(
									component.props.history.location.search,
									""
								) +
								"/" +
								row.Id +
								"?page=1&filter-narrator=" +
								constants.GUID_EMPTY
							}
							style={{
								backgroundColor: constants.COLORS.gray,
								color: "black",
								padding: "3px"
							}}
						>
							Unassigned narrator: {row.Stats.ArticleCountUnassignedNarrator}
						</Link>
					</div>
				)}

				{row.Stats.ArticleCountUnassignedProofListener > 0 && (
					<div style={{ paddingTop: "3px" }}>
						<Link
							href={
								component.props.path.replace(
									component.props.history.location.search,
									""
								) +
								"/" +
								row.Id +
								"?page=1&filter-proof_listener=" +
								constants.GUID_EMPTY
							}
							to={
								component.props.path.replace(
									component.props.history.location.search,
									""
								) +
								"/" +
								row.Id +
								"?page=1&filter-proof_listener=" +
								constants.GUID_EMPTY
							}
							style={{
								backgroundColor: constants.COLORS.gray,
								color: "black",
								padding: "3px"
							}}
						>
							Unassigned proof listener:{" "}
							{row.Stats.ArticleCountUnassignedProofListener}
						</Link>
					</div>
				)}
			</div>
		)
	};
};

export const createdColumn = () => {
	return {
		title: "Created",
		key: "created",
		dataIndex: "Created",
		render: (id, row) => (
			<Tooltip title="Created"> {formatDateTime(row.Created)}</Tooltip>
		),
		sorter: (a, b) => {
			return a.Created && a.Created.localeCompare(b.Created);
		},
		sortDirections: ["descend", "ascend"]
	};
};

export const defaultNarratorColumn = (users, component) => {
	let usersGroup =  [];

	if(users){
		usersGroup = users.find((u) => u.Name === "narrator").Users || [];
		if(!usersGroup.find((u)=>u.Id === constants.GUID_EMPTY)){
			usersGroup.push({ Id: constants.GUID_EMPTY, Name: 'Unassigned' })
		}
	 }

	return {
		title: 'Default narrator',
		key: 'DefaultNarratorId',
		dataIndex: 'DefaultNarratorId',
		filters: usersGroup.map((user) => {
			return {
				value: user.Id,
				text: splitUsername(user.Name)
			}
		}),
		onFilter: (value, row) => {
			return (
				row.DefaultNarratorId === value
			)
		},
		filteredValue: undefined,
		render: (id, row, test) => (
			<Tooltip title="Default narrator">{splitUsername(findUserById(usersGroup, id))}</Tooltip>
		)
	}
};
export const assignedPeopleColumn = (users, component) => {
	const usersGroup =  { narrators:[], prooflisteners: []};

	if(users){
		usersGroup.narrators = users.find((u) => u.Name === "narrator").Users || [];
		if(!usersGroup.narrators.find((u)=>u.Id === constants.GUID_EMPTY)){
			usersGroup.narrators.push({ Id: constants.GUID_EMPTY, Name: 'Unassigned' })
		}
		usersGroup.prooflisteners = users.find((u) => u.Name === "prooflistener").Users || [];
		if(!usersGroup.prooflisteners.find((u)=>u.Id === constants.GUID_EMPTY)){
			usersGroup.prooflisteners.push({ Id: constants.GUID_EMPTY, Name: 'Unassigned' })
		}
	}

	return {
		title: 'Assigned people',
		key: 'assignedPeople',
		dataIndex: ['Stats', 'ArticleCountByUserId'],
		filters: [{
			text: 'Narrators',
			value: 'Narrators',
			children: usersGroup.narrators.map((user) => {
				return {
					value: user.Id,
					text: splitUsername(user.Name)
				}
			})
		},
		{
			text: 'Prooflisteners',
			value: 'Prooflisteners',
			children: usersGroup.prooflisteners.map((user) => {
				return {
					value: user.Id,
					text: splitUsername(user.Name)
				}
			})
		}],
		onFilter: (value, row) => {
			return (
				typeof row.Stats.ArticleCountByUserId[value] !== 'undefined'
			)
		},
		filteredValue: undefined,
		render: (id, row) => (
			 <Tooltip title="Assigned people">
				 {Object.keys(getAssignedPeople(users, id)).map(key => (
					 <ul key={key}>
						 <b className={key}>{key}:</b>
						 {
							 getAssignedPeople(users, id)[key].map(user => (
								 <li key={user.Id}>{user.name}</li>
							 ))
						 }
					 </ul>
				 ))}
			 </Tooltip>
		)
	}
};

export const defaultProoflistenerColumn = (users, component) => {
	let usersGroup =  [];

	if(users){
		usersGroup = users.find((u) => u.Name === "prooflistener").Users || [];
		if(!usersGroup.find((u)=>u.Id === constants.GUID_EMPTY)){
			usersGroup.push({ Id: constants.GUID_EMPTY, Name: 'Unassigned' })
		}
	 }

	return {
		title: 'Default prooflistener',
		key: 'DefaultProofListenerId',
		dataIndex: 'DefaultProofListenerId',
		filters: usersGroup.map((user) => {
			return {
				value: user.Id,
				text: splitUsername(user.Name)
			}
		}),
		onFilter: (value, row) => {
			return (
				row.DefaultProofListenerId === value
			)
		},
		filteredValue: undefined,
		render: (id, row, test) => (
			<Tooltip title="Default proofListener"> {splitUsername(findUserById(usersGroup, id))}</Tooltip>
		)
	}
};

export const updatedColumn = () => {
	return {
		title: "Updated",
		key: "updated",
		dataIndex: "Updated",
		render: (id, row) => (
			<Tooltip title="Updated"> {formatDateTime(row.LastUpdated)}</Tooltip>
		),
		sorter: (a, b) => {
			return a.LastUpdated && a.LastUpdated.localeCompare(b.LastUpdated);
		},
		sortDirections: ["descend", "ascend"]
	};
};
export const enableDisableColumn = (component) => {
	return {
		title: "",
		key: "state",
		dataIndex: "State",
		render: (id, row) => (
			<div>
				<Popconfirm
					title="Are you sure you want to enable all articles?"
					onConfirm={() =>
						component.onStateBtnClick(component.props, row, "enable")
					}
					okText="Yes"
					cancelText="No"
				>
					<Button
						type="default"
						className="custom_button_width"
						block
						loading={
							component.props.isLoading.teachingMaterialId === row.Id &&
							component.props.isLoading.enable
						}
					>
						Enable All Articles
					</Button>
				</Popconfirm>
				<Popconfirm
					title="Are you sure you want to disable all articles?"
					onConfirm={() =>
						component.onStateBtnClick(component.props, row, "disable")
					}
					okText="Yes"
					cancelText="No"
				>
					<Button
						type="danger"
						className="disable__button"
						block
						loading={
							component.props.isLoading.teachingMaterialId === row.Id &&
							component.props.isLoading.disable
						}
					>
						Disable All Articles
					</Button>
				</Popconfirm>
			</div>
		),
		width: 100
	};
};
export const editColumn = (component) => {
	return {
		title: "Manage",
		key: "manage",
		dataIndex: "Manage",
		render: (id, row) => (
			<div style={{ display: "block" }}>
				<Button type="primary" onClick={() => component.showModal(row)}>
					<SettingOutlined />
				</Button>
			</div>
		),
		width: 50
	};
};
export const assignedToMeColumn = (user, component) => {
	return {
		title: "Articles Assigned To Me",
		key: "assigned",
		dataIndex: "Assigned",
		render: (id, row) => (
			<Tooltip title="Articles Assigned To Me">
				<Link
					href={
						component.props.path.replace(
							component.props.history.location.search,
							""
						) +
						"/" +
						row.Id +
						"?page=1&filter-state=" +
						(isNarrator(user)
							? `${constants.ARTICLE_STATE.Recording},${constants.ARTICLE_STATE.RecordingChanges}`
							: constants.ARTICLE_STATE.ProofListening)
					}
					to={
						component.props.path.replace(
							component.props.history.location.search,
							""
						) +
						"/" +
						row.Id +
						"?page=1&filter-state=" +
						(isNarrator(user)
							? `${constants.ARTICLE_STATE.Recording},${constants.ARTICLE_STATE.RecordingChanges}`
							: constants.ARTICLE_STATE.ProofListening)
					}
				>
					{row.Stats.ArticleCountAssignedToMe}
				</Link>
			</Tooltip>
		)
	};
};

export const assignedToMeGuidesColumn = (user, component) => {
	return {
		title: "Guides Assigned To Me",
		key: "assignedGuides",
		dataIndex: "AssignedGuides",
		render: (id, row) => (
			<Tooltip title="Guides Assigned To Me">
				<Link
					href={
						component.props.path.replace(
							component.props.history.location.search,
							""
						) +
						"/" +
						row.Id +
						"/guides" +
						"?page=1&filter-state=" +
						(isGuideNarrator(user)
							? `${constants.GUIDE_STATE.Recording},${constants.GUIDE_STATE.Manuscripting}`
							: constants.GUIDE_STATE.ProofListening)
					}
					to={
						component.props.path.replace(
							component.props.history.location.search,
							""
						) +
						"/" +
						row.Id +
						"/guides" +
						"?page=1&filter-state=" +
						(isGuideNarrator(user)
							? `${constants.GUIDE_STATE.Recording},${constants.GUIDE_STATE.Manuscripting}`
							: constants.GUIDE_STATE.ProofListening)
					}
				>
					{row.Stats.GuideCountAssignedToMe}
				</Link>
			</Tooltip>
		)
	};
};

export const assignedToMeForCorrectionsColumn = (component) => {
	return {
		title: "Articles Assigned To Me For Corrections",
		key: "corrections",
		dataIndex: "Corrections",
		render: (id, row) => (
			<Tooltip title="Articles Assigned To Me For Corrections">
				<Link
					href={
						component.props.path.replace(
							component.props.history.location.search,
							""
						) +
						"/" +
						row.Id +
						"?page=1&filter-state=" +
						constants.ARTICLE_STATE.CorrectionsNeeded
					}
					to={
						component.props.path.replace(
							component.props.history.location.search,
							""
						) +
						"/" +
						row.Id +
						"?page=1&filter-state=" +
						constants.ARTICLE_STATE.CorrectionsNeeded
					}
				>
					{row.Stats.ArticleCountAssignedToMeForCorrections}
				</Link>
			</Tooltip>
		)
	};
}

export const assignedToMeGuidesForCorrectionsColumn = (component) => {
	return {
		title: "Guides Assigned To Me For Corrections",
		key: "correctionsGuides",
		dataIndex: "CorrectionsGuides",
		render: (id, row) => (
			<Tooltip title="Guides Assigned To Me For Corrections">
				<Link
					href={
						component.props.path.replace(
							component.props.history.location.search,
							""
						) +
						"/" +
						row.Id +
						"/guides" +
						"?page=1&filter-state=" +
						constants.GUIDE_STATE.CorrectionsNeeded
					}
					to={
						component.props.path.replace(
							component.props.history.location.search,
							""
						) +
						"/" +
						row.Id +
						"/guides" +
						"?page=1&filter-state=" +
						constants.GUIDE_STATE.CorrectionsNeeded
					}
				>
					{row.Stats.GuideCountAssignedToMeForCorrections}
				</Link>
			</Tooltip>
		)
	};
};

export const assignedToMeWithArticleContentChanged = (component) => {
	return {
		title: "Published Guides which Articles got changes",
		key: "guidesWithChangedArticles",
		dataIndex: "GuidesWithChangedArticles",
		render: (id, row) => (
			<Tooltip title="Published Guides which Articles got changes">
				<Link
					href={
						component.props.path.replace(
							component.props.history.location.search,
							""
						) +
						"/" +
						row.Id +
						"/guides" +
						"?page=1&filter-state=" +
						constants.GUIDE_STATE.Published
					}
					to={
						component.props.path.replace(
							component.props.history.location.search,
							""
						) +
						"/" +
						row.Id +
						"/guides" +
						"?page=1&filter-state=" +
						constants.GUIDE_STATE.Published
					}
				>
					{row.Stats.GuideCountAssignedToMeWithArticleContentChanged}
				</Link>
			</Tooltip>
		)
	};
};
