export default (request) => ({
    disableGuide(publisherId, teachingMaterialId, articleId, guideId) {
        return request({
            path: `publisher/${publisherId}/teachingMaterial/${teachingMaterialId}/articles/${articleId}/guides/${guideId}/disable`,
            method: "patch"
        });
    },
    enableGuide(publisherId, teachingMaterialId, articleId, guideId) {
        return request({
            path: `publisher/${publisherId}/teachingMaterial/${teachingMaterialId}/articles/${articleId}/guides/${guideId}/enable`,
            method: "patch"
        });
    },
    setNarrator(publisherId, teachingMaterialId, articleId, guideId, narratorId) {
        return request({
            path: `publisher/${publisherId}/teachingMaterial/${teachingMaterialId}/articles/${articleId}/guides/${guideId}/narrator`,
            method: "patch",
            body: narratorId
        });
    },
    setProofListener(publisherId, teachingMaterialId, articleId, guideId, proofListenerId) {
        return request({
            path: `publisher/${publisherId}/teachingMaterial/${teachingMaterialId}/articles/${articleId}/guides/${guideId}/proofListener`,
            method: "patch",
            body: proofListenerId
        });
    },
    get(publisher, teachingMaterial) {
        return request({
            path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/guides`,
            method: "get"
        });
    },
    getAllGuidesLanguages() {
        return request({
            path: `guides/languages`,
            method: "get"
        });
    },
});
