import React, { Component } from "react";
import { Input, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import api from "../../api";
import SearchResults from "./SearchResults";
import "./searchForm.css";
import * as logger from "../../helpers/logger";

class Search extends Component {
	constructor(props) {
		super(props);
		this.state = {
			query: "",
			results: null,
			message: "",
			resultsActive: false,
			searchDisabled: true
		};

		this.doSearch = this.doSearch.bind(this);
		this.searchInput = this.searchInput.bind(this);
	}

	doSearch() {
		const { query } = this.state;

		this.setState({
			loadingSearch: true
		});

		api.search
			.get(query)
			.then((response) => {
				this.setState({
					results: response,
					resultsActive: true,
					loadingSearch: false
				});

				return response;
			})
			.catch((err) => {
				logger.error(err);
				this.setState({
					results: null,
					loadingSearch: false,
					message: err
				});
			});
		document.activeElement.blur();
	}

	searchInput(event) {
		const query = event.target.value;

		if (query.length < 5) {
			// Empty search query
			this.setState({
				query,
				resultsActive: query.length > 5 || false,
				searchDisabled: query.length < 5 || false,
				results: null,
				loadingSearch: false
			});
		} else {
			this.setState({
				query,
				searchDisabled: query.length < 5 || false,
				resultsActive: query.length > 5 || false
			});
		}
		return true;
	}

	render() {
		const {
			message,
			results,
			searchDisabled,
			resultsActive,
			loadingSearch
		} = this.state;

		return (
			<div className="search-container">
				<form
					onSubmit={(event) => {
						event.preventDefault();
						const { query } = this.state;
						if (query.length < 5) {
							return false;
						}

						this.doSearch();
					}}
					className="search-form-container"
				>
					<Input
						name="searchTerm"
						allowClear
						onFocus={() => {
							this.setState({
								resultsActive: !searchDisabled && results
							});
						}}
						onInput={this.searchInput}
						onChange={this.searchInput}
						ref={(el) => {
							this.searchInputField = el;
						}}
						placeholder="Search term"
					/>
					{loadingSearch ? (
						<Button
							disabled={!searchDisabled}
							icon={<LoadingOutlined />}
							type="button"
							onClick={this.doSearch}
						>
							Searching..
						</Button>
					) : (
						<Button
							disabled={searchDisabled}
							type="button"
							onClick={this.doSearch}
						>
							Find
						</Button>
					)}
				</form>

				{message ? (
					<div className="search-results">
						<h4>{message}</h4>
					</div>
				) : null}

				{results && resultsActive ? (
					<div className="search-results">
						<SearchResults data={results} />
					</div>
				) : null}
			</div>
		);
	}
}

export default Search;
