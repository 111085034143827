import React, { Component } from "react";
import PropTypes from "prop-types";
import Breadcrumb from "antd/lib/breadcrumb";
import Menu from "antd/lib/menu";
import { DownloadOutlined, HomeOutlined } from "@ant-design/icons";
import notification from "antd/lib/notification";
import { Link, withRouter } from "react-router-dom";
import { history, connector } from "../../store";
import { isAdminOrNarratorOrProofListener } from "../../helpers/authHelpers";

import "./index.css";
import FrontendBookmark from "../frontendBookmark";
import Search from "../search";

class Header extends Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.getNameByUrl = this.getNameByUrl.bind(this);
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.error) {
			notification.error({
				message: nextProps.error.response
					? nextProps.error.response.data.message[0]
					: nextProps.error.message,
				duration: 0
			});
		}
	}

	componentWillUnmount = () => {};

	getNameByUrl(url) {
		const pathSnippets = url.split("/").filter((i) => i);
		const { publishers } = this.props;
		const getTeachingMaterial = ()=>{
			const publisher = publishers?.find((p) => p.Id === pathSnippets[1]) || {
				TeachingMaterials: []
			};
			const teachingMaterials = publisher.TeachingMaterials || [];
			return  teachingMaterials.find(
				(t) => t.Id === pathSnippets[2].replace(window.location.search, "")
			) || { Title: null, Articles: [] };
		};
		switch (pathSnippets.length) {
			case 1:
				return "Publishers";
			case 2:
				return pathSnippets[1].replace(window.location.search, "");
			case 3:
				return getTeachingMaterial().Title;
			case 4:
				const teachingMaterial = getTeachingMaterial();
				const article = teachingMaterial.Articles.find(
					(a) => a.Id === pathSnippets[3].replace(window.location.search, "")
				) || {Title: null};
				return article.Title;
			default:
				return null;
		}
	}

	handleClick = (e) => {};

	render() {
		const { path, user } = this.props;

		const pathSnippets = path.split("/").filter((i) => i);
		const breadcrumbItems = pathSnippets.map((_, index) => {
			const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
			return (
				<Breadcrumb.Item key={url}>
					<Link to={url}>{this.getNameByUrl(url)}</Link>
				</Breadcrumb.Item>
			);
		});

		return (
			<div className="header">
				<div className="header-left">
					<Menu
						onClick={this.handleClick}
						selectedKeys={[history.location.key]}
						mode="horizontal"
					>
						<Menu.Item key="home" className="header__home">
							<Link to="/">
								<HomeOutlined />
								Home
							</Link>
						</Menu.Item>
						{isAdminOrNarratorOrProofListener(user) && (
							<Menu.Item key="export" className="header__export">
								<Link to="/export">
									<DownloadOutlined />
									File Exports
								</Link>
							</Menu.Item>
						)}
					</Menu>
					<Search />

					<Breadcrumb className="header__breadcrumbs">
						{breadcrumbItems}
					</Breadcrumb>
				</div>
				<div className="header-right">
					<FrontendBookmark />
				</div>
				<div className="clearfix" />
			</div>
		);
	}
}

Header.propTypes = {
	publishers: PropTypes.arrayOf(PropTypes.object),
	user: PropTypes.object.isRequired,
	path: PropTypes.string,
	error: PropTypes.object,
	action: PropTypes.shape({
		disableAllNewArticles: PropTypes.func.isRequired
	}).isRequired
};

Header.defaultProps = {
	path: "",
	error: null,
	publishers: []
};

export default withRouter(
	connector(Header, (props) => ({
		user: props.user,
		path: props.path,
		error: props.error,
		publishers: props.publishers,
		action: {
			disableAllNewArticles: props.action.disableAllNewArticles
		}
	}))
);
