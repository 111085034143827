import React, { Component } from "react";
import "./App.css";
import Amplify from "aws-amplify";
import {
	Authenticator,
	SignIn,
	ConfirmSignIn,
	VerifyContact,
	ForgotPassword,
	RequireNewPassword,
	Greetings
} from "aws-amplify-react";
import PropTypes from "prop-types";
import * as logger from "./helpers/logger";
import Loading from "./components/loading";
import awsExports from "./awsExports";
import Routing from "./components/routing";
import { connector } from "./store";
import "@aws-amplify/ui/dist/style.css";

console.debug(awsExports);
Amplify.Logger.LOG_LEVEL =
	process.env.REACT_APP_ENV.toLowerCase() === "production" ? "WARN" : "DEBUG";
Amplify.configure(awsExports);

const CustomTheme = {
	sectionHeader: {
		backgroundImage: `url(/logo.jpg)`,
		height: "100px",
		width: "100%",
		backgroundSize: "200px 100px",
		backgroundRepeat: "no-repeat",
		backgroundPositionX: "center",
		paddingTop: "120px",
		paddingBottom: "20px"
	},
	a: { color: "#337ab7" },
	button: { backgroundColor: "#337ab7", color: "#fff" },
	navButton: { backgroundColor: "#337ab7", color: "#fff" },
	formSection: {
		boxShadow: "0 5px 15px rgba(0,0,0,.5)"
	}
};

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			authState: null
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextProps.authState !== this.props.authState;
	}

	render() {
		const { isLoading, action } = this.props;
		const { authState } = this.state;
		if (
			isLoading.users ||
			isLoading.publishers ||
			isLoading.teachingMaterials ||
			isLoading.articles
		) {
			return <Loading />;
		}

		return (
			<div className="appContainer">
				<div className="overlay"></div>
				<div className="app">
					<Authenticator
						theme={CustomTheme}
						onStateChange={(newAuthState) => {
							if (newAuthState !== authState) {
								logger.debug(
									"auth state change: " + authState + " => " + newAuthState
								);
								this.setState({
									authState: newAuthState
								});
								action.setAuthState(newAuthState);
								action.clearData(newAuthState);
								if (newAuthState === "signedIn") {
									document
										.querySelector("#root .overlay")
										.classList.remove("active");
									action.getUser();
								} else {
									document
										.querySelector("#root .overlay")
										.classList.add("active");
								}
							}
						}}
						amplifyConfig={awsExports}
						hideDefault
					>
						<Greetings />
						<SignIn />
						<ConfirmSignIn />
						<RequireNewPassword />
						<VerifyContact />
						<ForgotPassword />
						<Routing />
					</Authenticator>
				</div>
			</div>
		);
	}
}

App.propTypes = {
	isLoading: PropTypes.object.isRequired,
	action: PropTypes.shape({
		getUser: PropTypes.func.isRequired,
		clearData: PropTypes.func.isRequired,
		setAuthState: PropTypes.func.isRequired
	}).isRequired
};

export default connector(App, (props) => ({
	action: {
		clearData: props.action.clearData,
		getUser: props.action.getUser,
		setAuthState: props.action.setAuthState
	},
	isLoading: props.isLoading
}));
