export default request => ({
	getRoyalty(from, to, asJson = false) {
		return request({
			path: `export/royalty/?from=${from}&to=${to}&asJson=${asJson}`,
			method: "get"
		});
	},
	getWorklog(from, to, asJson = false) {
		return request({
			path: `export/worklog/?from=${from}&to=${to}&asJson=${asJson}`,
			method: "get"
		});
	},
	getWorkedTimeToday() {
		return request({
			path: `workedtoday`,
			method: "get"
		});
	}
});
