import React from "react";
import Tooltip from "antd/lib/tooltip";
import Button from "antd/lib/button";
import {MoreOutlined} from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import {Checkbox, Popconfirm} from "antd";
import {Link} from "react-router-dom";
import * as constants from "../../constants";
import {
	splitUsername,
	getNarrators,
	getProofListeners
} from "../../helpers/authHelpers";
import SelectUserForm from "../../forms/selectUserForm";
import getColumnSearchProps from "../columnSearch";

export const orderColumn = () => {
	return {
		title: "Order",
		key: "order",
		dataIndex: "Order",
		render: (id, row) => <Tooltip title="Order"> {row.Order}</Tooltip>,
		width: 40,
		defaultSortOrder: "ascend",
		sorter: (a, b) => a.Order - b.Order,
		sortDirections: ["descend", "ascend"]
	};
};

export const titleColumn = (component) => {
	const titleFilter = component.state.filters.title;
	if (titleFilter) {
		component.state.searchedColumn = "title";
		component.state.searchText = titleFilter;
	}
	return {
		title: "Title",
		key: "title",
		dataIndex: "Title",
		width: 450,
		sorter: (a, b) => {
			return a.Title && a.Title.localeCompare(b.Title);
		},
		render: (Id, row) =>
			component.state.searchedColumn === "title" ? (
				<a
					href={row.Url}
					to={row.Url}
					target="_blank"
					rel="noopener noreferrer"
				>
					<Highlighter
						highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
						searchWords={[component.state.searchText]}
						autoEscape
						textToHighlight={row.Title}
					/>
				</a>
			) : (
				<a
					href={row.Url}
					to={row.Url}
					target="_blank"
					rel="noopener noreferrer"
				>
					{row.Title}
				</a>
			),
		sortDirections: ["descend", "ascend"],
		...getColumnSearchProps("Title", null, component.setSearchText, component.setSearchColumn),
		filteredValue: titleFilter ? [titleFilter] : undefined
	};
};


export const enableGuides = (component) => {
	const {publishers, match} = component.props;
	const publisher = publishers?.find(
		(p) => p.Id === match.params.publisherId
	) || {GuidesEnabled: false};
	if (!publisher.GuidesEnabled) return {};

	return {
		title: "Enable Guides",
		key: "guidesEnabled",
		dataIndex: "GuidesEnabled",
		render: (id, row) => (
			<div>
                <Tooltip title="Enable Guide">
                    {row.GuidesEnabled ? (
                        <span>
                            <Popconfirm
                                title="After guides will be disabled for Article, all Article guides will be disabled!"
                                onConfirm={() => component.onEnableGuidesCheckboxClick(component.props, row)}
                            >
                                <Checkbox
                                    checked={row.GuidesEnabled}
                                    key={row.id + "-guidesEnabled"}
                                    loading={
                                        component.props.isLoading.article === row.Id &&
                                        component.props.isLoading.disable
                                    }
                                />
                            </Popconfirm>
                            <Tooltip title="Article's guides list">
                                <Link to={`${component.props.match.params.teachingMaterialId}/${row.Id}`}>
                                    <MoreOutlined
                                        style={{marginLeft: "20px"}}
                                    />
                                </Link>
                            </Tooltip>
                        </span>
                    ) : (
                        <Checkbox
                            checked={row.GuidesEnabled}
                            key={row.id + "-guidesEnabled"}
                            loading={
                                component.props.isLoading.article === row.Id &&
                                component.props.isLoading.disable
                            }
                            onClick={() => component.onEnableGuidesCheckboxClick(component.props, row)}
                        />

                    )}
                </Tooltip>
			</div>
		),
		sorter: (a, b) => {
			return a.GuidesEnabled > b.GuidesEnabled;
		},
		sortDirections: ["descend", "ascend"]
	};
};
export const selectNarratorColumn = (users, articles, component) => {
	const selectedFilters = component.state.filters.narrator;
	const existingNarrators = [];
	articles.forEach((article) => {
		getNarrators(users).forEach((user) => {
			if (user.Id === article.NarratorId && !existingNarrators.includes(user)) {
				existingNarrators.push(user);
			}
		});
	});
	return {
		title: "Narrator",
		key: "narrator",
		dataIndex: "Narrator",
		filters: existingNarrators
			.map(function (user) {
				return {
					value: user.Id,
					text: splitUsername(user.Name)
				};
			})
			.concat([
				{
					value: constants.GUID_EMPTY,
					text: "Unassigned"
				}
			]),
		onFilter: (value, record) => record.NarratorId === value,
		filteredValue: selectedFilters ? selectedFilters.split(",") : undefined,
		render: (id, row) => (
			<SelectUserForm
				users={component.props.users}
				userType="narrator"
				row={row}
				onChange={component.onChangeNarratorOrProofListener}
				match={component.props.match}
				action={component.props.action}
			/>
		),
		width: 200
	};
};
export const selectProofListenerColumn = (users, articles, component) => {
	const selectedFilters = component.state.filters.proof_listener;
	const existingProofListeners = [];
	articles.forEach((article) => {
		getProofListeners(users).forEach((user) => {
			if (
				user.Id === article.ProofListenerId &&
				!existingProofListeners.includes(user)
			) {
				existingProofListeners.push(user);
			}
		});
	});
	return {
		title: "Proof Listener",
		key: "proof_listener",
		dataIndex: "Proof_Listener",
		filters: existingProofListeners
			.map(function (user) {
				return {
					value: user.Id,
					text: splitUsername(user.Name)
				};
			})
			.concat([
				{
					value: constants.GUID_EMPTY,
					text: "Unassigned"
				}
			]),
		onFilter: (value, record) => record.ProofListenerId === value,
		filteredValue: selectedFilters ? selectedFilters.split(",") : undefined,
		render: (id, row) => (
			<SelectUserForm
				users={component.props.users}
				userType="prooflistener"
				row={row}
				onChange={component.onChangeNarratorOrProofListener}
				match={component.props.match}
				action={component.props.action}
				showConfirmPopup={false}
			/>
		),
		width: 200
	};
};
export const enableDisableColumn = (component) => {
	return {
		title: "",
		key: "enabled_state",
		dataIndex: "Enabled_State",
		render: (id, row) => {
			const result = [];
			if (row.State !== constants.ARTICLE_STATE.Disabled) {
				result.push(
					<Button
						className="articles_custom_button_width"
						type="danger"
						key={row.id + "-disable"}
						block
						loading={
							component.props.isLoading.article === row.Id &&
							component.props.isLoading.disable
						}
						onClick={() => component.onStateBtnClick(component.props, row)}
					>
						Disable{" "}
					</Button>
				);
			} else {
				result.push(
					<span key={row.id + "-enable"}>
						<Popconfirm
							title={<div><p>Are you sure you want to enable this article?</p><i>This could un-publish in-chapter articles if the current article narrator differs to narrator of published articles.</i></div>}
							onConfirm={() => component.onStateBtnClick(component.props, row)}
						>
							<Button
								className="articles_custom_button_width"
								type="default"
								block
								loading={
									component.props.isLoading.article === row.Id &&
									component.props.isLoading.enable
								}
							>
								Enable
							</Button>
						</Popconfirm>
					</span>
				);
			}
			if (row.State === constants.ARTICLE_STATE.ReadyForPublishing) {
				result.push(
					<Button
						type="primary"
						className="action__button articles_custom_button_width"
						key={row.id + "-publish"}
						block
						loading={
							component.props.isLoading.article === row.Id &&
							component.props.isLoading.publishing
						}
						onClick={() => component.onPublishBtnClick(component.props, row.Id)}
					>
						Publish
					</Button>
				);
			}
			if (row.State === constants.ARTICLE_STATE.Published) {
				result.push(
					<Button
						type="primary"
						className="action__button articles_custom_button_width"
						key={row.id + "-unpublish"}
						block
						loading={
							component.props.isLoading.article === row.Id &&
							component.props.isLoading.unpublishing
						}
						onClick={() =>
							component.onUnpublishBtnClick(component.props, row.Id)
						}
					>
						Unpublish
					</Button>
				);
			}

			return result;
		},
		width: 120
	};
};
export const linkColumn = (component) => {
	return {
		title: "",
		key: "link",
		dataIndex: "Link",
		render: (Id, row) => (
			<div style={{ minWidth: "150px" }}>
				<Tooltip title="More">
					<MoreOutlined
						style={{ float: "left" }}
						onClick={() => component.showModal(row)}
					/>
				</Tooltip>
			</div>
		),
		width: 20
	};
};