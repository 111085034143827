import React from "react";
import {Menu, Dropdown, Popconfirm} from "antd";
import { DownOutlined } from "@ant-design/icons";

import PropTypes from "prop-types";
import * as constants from "../constants";
import { splitUsername } from "../helpers/authHelpers";

const SelectUserForm = (props) => {
	const [visible, showPopconfirm] = React.useState(false);
	const [activeKey, setActiveKey] = React.useState(constants.GUID_EMPTY);
	const { users, userType, onChange, row, showConfirmPopup } = props;
	const userGroup = users.find((u) => u.Name === userType) || [];

	const menu = (
		<Menu
			onClick={(e) => {
				setActiveKey(String(e.key));
				if (showConfirmPopup) {
					showPopconfirm(true)
				} else {
					onChange(props, e.key);
				}
			}}
		>
			<Menu.Item key={constants.GUID_EMPTY}>Unassigned</Menu.Item>
			{userGroup.Users &&
				userGroup.Users.map((u, i) => (
					<Menu.Item key={u.Id}>{splitUsername(u.Name)}</Menu.Item>
				))}
		</Menu>
	);

	const currentId =
		userType.includes("narrator") ? row.NarratorId : row.ProofListenerId;
	const currentUser = userGroup.Users ?userGroup.Users.find((p) => p.Id === currentId) : false;
	const currentName = currentUser ? currentUser.Name : "Unassigned";

	return (
		<Popconfirm
			title="By changing narrator of this article you will depublish the article and all other articles in the same chapter."
			visible={visible}
			onConfirm={() => {onChange(props, activeKey); showPopconfirm(false);}}
			onCancel={() => {showPopconfirm(false)}}
		>
			<Dropdown overlay={menu}>
				<button
					type="button"
					className="ant-dropdown-link"
					onClick={(e) => e.preventDefault()}
				>
					{currentName} <DownOutlined />
				</button>
			</Dropdown>
		</Popconfirm>
	);
};

SelectUserForm.propTypes = {
	users: PropTypes.arrayOf(PropTypes.object).isRequired,
	onChange: PropTypes.func.isRequired,
	userType: PropTypes.string.isRequired,
	row: PropTypes.object.isRequired,
	showConfirmPopup: PropTypes.bool
};

SelectUserForm.defaultProps = {
	showConfirmPopup: true
};

export default SelectUserForm;
