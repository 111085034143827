import Input from "antd/lib/input";
import Button from "antd/lib/button";
import {SearchOutlined} from "@ant-design/icons";
import React from "react";

const handleReset = (clearFilters, setSearchText) => {
    clearFilters();
    setSearchText("");
}

const handleSearch = (selectedKeys, confirm, dataIndex, setSearchText, setSearchedColumn) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
};

let searchInput = {};

const getColumnSearchProps = (dataIndex, filterLastN, setSearchText, setSearchedColumn) => {
    const filterDropdown = ({
                                setSelectedKeys,
                                selectedKeys,
                                confirm,
                                clearFilters
                            }) => (
        <div style={{padding: 8}}>
            <Input
                ref={(node) => {
                    searchInput = node;
                }}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{width: 188, marginBottom: 8, display: "block"}}
            />
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex, setSearchText, setSearchedColumn)}
                icon={<SearchOutlined />}
                size="small"
                style={{width: 90, marginRight: 8}}
            >
                Search
            </Button>
            <Button
                onClick={() => handleReset(clearFilters, setSearchText)}
                size="small"
                style={{width: 90}}
            >
                Reset
            </Button>
        </div>
    );
    const filterIcon = (filtered) => (
        <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />
    );
    const onFilter = (value, record) => {
        return (filterLastN
                ? record[dataIndex].substr(record[dataIndex].length - filterLastN)
                : record[dataIndex]
        )
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
    };
    const onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => searchInput.select());
        }
    };

    return {
        filterDropdown,
        filterIcon,
        onFilter,
        onFilterDropdownVisibleChange
    }
};

export default getColumnSearchProps;