/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import PropTypes from "prop-types";
import "./index.css";
import Alert from "antd/lib/alert";

import * as constants from "../../constants";

export default class StepProgressBar extends Component {
	static propTypes = {
		article: PropTypes.object.isRequired
	};

	render() {
		const { article } = this.props;
		if (article.State === constants.ARTICLE_STATE.Disabled) {
			return (
				<Alert
					style={{ marginBottom: "10px" }}
					message="This article is disabled"
					type="error"
				/>
			);
		}
		if (article.State === constants.ARTICLE_STATE.Undefined) {
			return "";
		}
		if (article.State === constants.ARTICLE_STATE.RecordingChanges) {
			return (
				<Alert
					style={{ marginBottom: "10px" }}
					message="This article has breaking changes."
					type="warning"
				/>
			);
		}

		const assignedClass =
			article.State > constants.ARTICLE_STATE.New ? "completed" : "";
		const recordingClass =
			article.State === constants.ARTICLE_STATE.Recording ||
			article.State === constants.ARTICLE_STATE.CorrectionsNeeded
				? "active"
				: article.State > constants.ARTICLE_STATE.Recording
				? "completed"
				: "";
		const proofListeningClass =
			article.State === constants.ARTICLE_STATE.ProofListening
				? "active"
				: article.State > constants.ARTICLE_STATE.ProofListening
				? "completed"
				: "";
		const processingClass =
			article.State === constants.ARTICLE_STATE.ReadyForAudioProcessing ||
			article.State === constants.ARTICLE_STATE.ProcessingAudio
				? "active"
				: article.State === constants.ARTICLE_STATE.ReadyForPublishing ||
				  article.State === constants.ARTICLE_STATE.Published
				? "completed"
				: "";
		const readyToPublishClass =
			article.State === constants.ARTICLE_STATE.ReadyForPublishing
				? "active"
				: article.State === constants.ARTICLE_STATE.Published
				? "completed"
				: "";
		const publishedClass =
			article.State === constants.ARTICLE_STATE.Published ? "completed" : "";

		return (
			<ul className="progress-indicator">
				<li key="assigned" className={assignedClass}>
					<span className="bubble" />
					<span>
						<small>Assigned</small>
					</span>
				</li>
				<li key="recording" className={recordingClass}>
					<span className="bubble" />
					<span>
						<small>Recording</small>
					</span>
				</li>
				<li key="proof" className={proofListeningClass}>
					<span className="bubble" />
					<span>
						<small>Proof listening</small>
					</span>
				</li>
				<li key="processing" className={processingClass}>
					<span className="bubble" />
					<span>
						<small>Processing</small>
					</span>
				</li>
				<li key="ready" className={readyToPublishClass}>
					<span className="bubble" />
					<span>
						<small>Ready to publish</small>
					</span>
				</li>
				<li key="published" className={publishedClass}>
					<span className="bubble" />
					<span>
						<small>Published!</small>
					</span>
				</li>
			</ul>
		);
	}
}
