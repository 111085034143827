import qs from "qs";
import { Auth } from "aws-amplify";
import message from "antd/lib/message";

import * as logger from "../helpers/logger";
import HTTP_STATUS_CODES from "./httpStatusCodes";

const contentType = "application/vnd.api+json";
const defaults = {
	Accept: contentType,
	"Content-Type": contentType
};

const buildHeaders = (headers) => {
	return Auth.currentSession()
		.then((data) => ({
			...defaults,
			...headers,
			Authorization: `Bearer ${data.getIdToken().getJwtToken()}`
		}))
		.catch((err) => {
			logger.error(err, "Failed to get jwt from cognito and build headers");
			return { ...defaults, ...headers };
		});
};

const Requestor = ({ apiUrl, heads = {} }) => (req) =>
	buildHeaders(heads)
		.then((headers) => {
			// make fetchcall with apicall
			const { path, method, data = {}, body = undefined } = req;
			const queryString = data && qs.stringify(data, { encode: true });
			const requestUrl = getRequestUrl(apiUrl, path, queryString);
			const options = {
				headers,
				mode: "cors",
				credentials: "omit",
				"Cache-Control": "no-cache",
				method: method.toUpperCase()
			};
			if (method.toUpperCase() !== "GET" && typeof body !== "undefined") {
				options.body =
					headers["Content-Type"] === "application/vnd.api+json"
						? JSON.stringify(body)
						: body;
			}
			try {
				return makeRequest(requestUrl, options).catch((err) => {
					logger.error(err, "makeRequest failed");
				});
			} catch (err) {
				logger.error(err, "Caught exception during makeRequest");
				return null;
			}
		})
		.catch((err) => {
			logger.error(err, "Failed to build request");
			return null;
		});

const getRequestUrl = (apiUrl, path, queryString) => {
	let url = String(apiUrl);
	if (url.endsWith("/")) {
		url = url.slice(0, -1);
	}

	return `${url}/${path}${queryString ? "?" + queryString : ""}`;
};

const makeRequest = (url, options) => {
	let responseCopy;
	logger.log("Requesting " + url, options);
	return fetch(url, options)
		.then((response) => {
			logger.debug(
				"Got response (" +
					response.status +
					") from " +
					options.method +
					" " +
					url,
				response
			);
			responseCopy = response.clone();
			if (response.status >= 200 && response.status <= 300) {
				if (response.status === 204) {
					return null;
				}
			} else {
				return handleUnexpectedError(
					url,
					`${response.status} ${HTTP_STATUS_CODES[response.status]}`
				);
			}

			if (
				response.headers.get("content-type").indexOf("application/json") !== -1
			) {
				return response
					.json()
					.then((responseBody) => {
						if (!response.ok) {
							logger.warn(responseBody);
							message.error(responseBody.message);
							return {
								...responseBody,
								errors: [responseBody.message]
							};
						}
						return responseBody;
					})
					.catch((err) => {
						return responseCopy.text().then((responseBody) => {
							return handleUnexpectedError(
								url,
								err + ". Response message: " + responseBody
							);
						});
					});
			}
			return response.blob();
		})
		.catch((err) => {
			return handleUnexpectedError(url, err);
		});
};

const handleUnexpectedError = (url, err) => {
	logger.error("Unsuccessful API call to", url, "Reason: ", err);
	if (err && typeof err === "string" && err.includes("403 Forbidden")) {
		message.error(
			`Oops! You don't seem to have access to this. 
		Make sure your account has been assigned to a group and reload the page to try again. 
		You will now be signed out.`,
			0
		);
		Auth.signOut();
	} else {
		message.error(
			"Failed to contact server. Please try again later. See development console for more information."
		);
	}
	return {
		errors: ["Unexpected error: " + err]
	};
};

export default Requestor;
