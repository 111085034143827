import api from "../../api";
import errorHandler from "./errorHandler";
import * as logger from "../../helpers/logger";
import * as constants from "../../constants";

export const getPublishersIfNeeded = (cb) => {
	return async (dispatch, getState) => {
		const publishers = getState().publishers || null;
		if (!publishers) {
			return dispatch(getPublishers(cb));
		}
		return publishers;
	};
};

export const getPublishers = (cb) => {
	return async (dispatch) => {
		dispatch({
			type: "FETCHING_PUBLISHERS"
		});
		return api.publishers
			.get()
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_PUBLISHERS",
					publishers: data
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.catch((err) => {
				logger.error(err, "Failed to get publishers");
				return Promise.reject(err);
			});
	};
};

export const getTeachingMaterialsIfNeeded = (publisherId, cb) => {
	return async (dispatch, getState) => {
		const publisher = getState().publishers?.find(
			(p) => p.Id === publisherId
		) || {
			TeachingMaterials: null
		};
		const teachingMaterials = publisher.TeachingMaterials || null;
		if (!teachingMaterials) {
			return dispatch(getTeachingMaterials(publisherId, cb));
		}
		return teachingMaterials;
	};
};

export const getTeachingMaterials = (publisher, cb) => {
	return async (dispatch) => {
		dispatch({
			type: "SET_TEACHING_MATERIALS_REQUEST",
			publisher
		});
		return api.teachingMaterials
			.get(publisher)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_TEACHING_MATERIALS",
					publisher,
					data
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.catch((err) => {
				logger.error(err, "Failed to get materials");
				return Promise.reject(err);
			});
	};
};

export const updateDefaultNarrator = (
	publisher,
	teachingMaterial,
	user,
	overwrite,
	cb
) => {
	return async (dispatch) => {
		dispatch({
			type: "UPDATE_DEFAULT_NARRATOR_REQUEST"
		});
		return api.teachingMaterials
			.updateDefaultNarrator(publisher, teachingMaterial, user, overwrite)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "UPDATE_DEFAULT_NARRATOR_SUCCESS",
					param: "publisher",
					publisher,
					teachingMaterial,
					user
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => dispatch(getArticles(publisher, teachingMaterial)))
			.catch((err) => {
				logger.error(err, "Failed to update default narrator");
				return Promise.reject(err);
			});
	};
};

export const updateDefaultGuideNarrator = (
	publisher,
	teachingMaterial,
	language,
	user,
	overwrite,
	cb
) => {
	return async (dispatch) => {
		dispatch({
			type: "UPDATE_DEFAULT_GUIDE_NARRATOR_REQUEST"
		});
		return api.teachingMaterials
			.updateDefaultGuideNarrator(publisher, teachingMaterial, language, user, overwrite)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "UPDATE_DEFAULT_GUIDE_NARRATOR_SUCCESS",
					param: "publisher",
					publisher,
					teachingMaterial,
					language,
					user
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => dispatch(getArticles(publisher, teachingMaterial)))
			.catch((err) => {
				logger.error(err, "Failed to update default guide narrator");
				return Promise.reject(err);
			});
	};
};

export const updateDefaultGuideProofListener = (
	publisher,
	teachingMaterial,
	language,
	user,
	overwrite,
	cb
) => {
	return async (dispatch) => {
		dispatch({
			type: "UPDATE_DEFAULT_GUIDE_PROOF_LISTENER_REQUEST"
		});
		return api.teachingMaterials
			.updateDefaultGuideProofListener(publisher, teachingMaterial, language, user, overwrite)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "UPDATE_DEFAULT_GUIDE_PROOF_LISTENER_SUCCESS",
					param: "publisher",
					publisher,
					teachingMaterial,
					language,
					user
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => dispatch(getArticles(publisher, teachingMaterial)))
			.catch((err) => {
				logger.error(err, "Failed to update default guide proof listener");
				return Promise.reject(err);
			});
	};
};

export const updateDefaultProofListener = (
	publisher,
	teachingMaterial,
	user,
	overwrite,
	cb
) => {
	return async (dispatch) => {
		dispatch({
			type: "UPDATE_DEFAULT_PROOF_LISTENER_REQUEST"
		});
		return api.teachingMaterials
			.updateDefaultProofListener(publisher, teachingMaterial, user, overwrite)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "UPDATE_DEFAULT_PROOF_LISTENER_SUCCESS",
					param: "publisher",
					publisher,
					teachingMaterial,
					user
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => dispatch(getArticles(publisher, teachingMaterial)))
			.catch((err) => {
				logger.error(err, "Failed to update default proof listener");
				return Promise.reject(err);
			});
	};
};

export const getArticlesIfNeeded = (publisherId, teachingMaterialId, cb) => {
	return async (dispatch, getState) => {
		const publisher = getState().publishers?.find(
			(p) => p.Id === publisherId
		) || {
			TeachingMaterials: []
		};
		const teachingMaterial = publisher.TeachingMaterials.find(
			(p) => p.Id === teachingMaterialId
		) || { Articles: [] };
		const articles = teachingMaterial.Articles || [];

		if (articles.length === 0) {
			return dispatch(getArticles(publisherId, teachingMaterialId, cb));
		}
		return articles;
	};
};

export const getArticles = (publisher, teachingMaterial, cb) => {
	return async (dispatch) => {
		dispatch({
			type: "FETCHING_ARTICLES"
		});
		return api.articles
			.get(publisher, teachingMaterial)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_ARTICLES",
					publisher,
					teachingMaterial,
					data
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.catch((err) => {
				logger.error(err, "Failed to get articles");
				return Promise.reject(err);
			});
	};
};

export const getArticle = (publisher, teachingMaterial, article, cb) => {
	return async (dispatch) => {
		dispatch({
			type: "FETCHING_ARTICLE"
		});
		return api.articles
			.getArticle(publisher, teachingMaterial, article)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_ARTICLE",
					publisher,
					teachingMaterial,
					data
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.catch((err) => {
				logger.error(err, "Failed to get article");
				return Promise.reject(err);
			});
	};
};

export const removeArticle = (publisher, teachingMaterialId, articleId, cb) => {
	return async (dispatch) => {
		dispatch({
			type: "REMOVE_ARTICLE",
			publisher,
			teachingMaterialId,
			articleId
		});
		if (typeof cb === "function") cb(articleId);
	};
};

export const publishArticle = (publisher, teachingMaterial, article, cb) => {
	return async (dispatch) => {
		dispatch({
			type: "PUBLISH_ARTICLE_REQUEST",
			article
		});
		return api.articles
			.publish(publisher, teachingMaterial, article)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "PUBLISH_ARTICLE_SUCCESS",
					publisher,
					teachingMaterial,
					article,
					data
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => dispatch(getArticle(publisher, teachingMaterial, article)))
			.catch((err) => {
				logger.error(err, "Failed to publish article");
				return Promise.reject(err);
			});
	};
};

export const unpublishArticle = (publisher, teachingMaterial, article, cb) => {
	return async (dispatch) => {
		dispatch({
			type: "UNPUBLISH_ARTICLE_REQUEST",
			article
		});
		return api.articles
			.unpublish(publisher, teachingMaterial, article)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "UNPUBLISH_ARTICLE_SUCCESS",
					publisher,
					teachingMaterial,
					article,
					data
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => dispatch(getArticle(publisher, teachingMaterial, article)))
			.catch((err) => {
				logger.error(err, "Failed to unpublish article");
				return Promise.reject(err);
			});
	};
};

export const resetArticle = (publisher, teachingMaterial, article, cb) => {
	return async (dispatch) => {
		dispatch({
			type: "RESET_ARTICLE_REQUEST",
			article
		});
		return api.articles
			.reset(publisher, teachingMaterial, article)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "RESET_ARTICLE_SUCCESS",
					param: "publisher",
					publisher,
					teachingMaterial,
					article,
					state: constants.ARTICLE_STATE.New
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => dispatch(getArticle(publisher, teachingMaterial, article)))
			.catch((err) => {
				logger.error(err, "Failed to reset article");
				return Promise.reject(err);
			});
	};
};

export const reProcessArticle = (publisher, teachingMaterial, article, cb) => {
	return async (dispatch) => {
		dispatch({
			type: "REPROCESS_ARTICLE_REQUEST",
			article
		});
		return api.articles
			.reprocess(publisher, teachingMaterial, article)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "REPROCESS_ARTICLE_SUCCESS",
					param: "publisher",
					publisher,
					teachingMaterial,
					article,
					state: constants.ARTICLE_STATE.ReadyForAudioProcessing
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => dispatch(getArticle(publisher, teachingMaterial, article)))
			.catch((err) => {
				logger.error(err, "Failed to reset article");
				return Promise.reject(err);
			});
	};
};

export const reDetectSentencesInArticle = (
	publisher,
	teachingMaterial,
	article,
	cb
) => {
	return async (dispatch) => {
		dispatch({
			type: "REDETECT_ARTICLE_REQUEST",
			article
		});
		return api.articles
			.redetect(publisher, teachingMaterial, article)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "REDETECT_ARTICLE_SUCCESS",
					param: "publisher",
					publisher,
					teachingMaterial,
					article,
					data
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => dispatch(getArticle(publisher, teachingMaterial, article)))
			.catch((err) => {
				logger.error(err, "Failed to re-detect sentences in article");
				return Promise.reject(err);
			});
	};
};

export const reDetectSentencesInMaterial = (
	publisher,
	teachingMaterial,
	article,
	cb
) => {
	return async (dispatch) => {
		dispatch({
			type: "REDETECT_MATERIAL_REQUEST",
			teachingMaterial
		});
		return api.teachingMaterials
			.redetect(publisher, teachingMaterial)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "REDETECT_MATERIAL_SUCCESS",
					param: "publisher",
					publisher,
					teachingMaterial,
					data
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => dispatch(getArticles(publisher, teachingMaterial, cb)))
			.catch((err) => {
				logger.error(err, "Failed to re-detect sentences in teaching material");
				return Promise.reject(err);
			});
	};
};

export const setProofListener = (
	publisher,
	teachingMaterial,
	article,
	user,
	cb
) => {
	return async (dispatch) => {
		dispatch({
			type: "SET_PROOF_LISTENER_REQUEST",
			article
		});
		return api.articles
			.setProofListener(publisher, teachingMaterial, article, user)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_PROOF_LISTENER_SUCCESS",
					param: "publisher",
					publisher,
					teachingMaterial,
					article,
					user
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => dispatch(getArticle(publisher, teachingMaterial, article)))
			.catch((err) => {
				logger.error(err, "Failed to set proof listener");
				return Promise.reject(err);
			});
	};
};

export const setNarrator = (publisher, teachingMaterial, article, user, cb) => {
	return async (dispatch) => {
		dispatch({
			type: "SET_NARRATOR_REQUEST",
			article
		});
		return api.articles
			.setNarrator(publisher, teachingMaterial, article, user)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_NARRATOR_SUCCESS",
					param: "publisher",
					publisher,
					teachingMaterial,
					article,
					user
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => dispatch(getArticle(publisher, teachingMaterial, article)))
			.catch((err) => {
				logger.error(err, "Failed to set narrator");
				return Promise.reject(err);
			});
	};
};

export const setGuideProofListener = (
	publisherId,
	teachingMaterialId,
	articleId,
	guideId,
	userId,
	cb
) => {
	return async (dispatch) => {
		dispatch({
			type: "SET_PROOF_LISTENER_REQUEST",
			article: articleId
		});
		return api.guides
			.setProofListener(publisherId, teachingMaterialId, articleId, guideId, userId)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_PROOF_LISTENER_SUCCESS",
					param: "publisher",
					publisher: publisherId,
					teachingMaterial: teachingMaterialId,
					article: articleId,
					user: userId
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => dispatch(getArticle(publisherId, teachingMaterialId, articleId)))
			.catch((err) => {
				logger.error(err, "Failed to set proof listener");
				return Promise.reject(err);
			});
	};
};

export const setGuideNarrator = (publisherId, teachingMaterialId, articleId, guideId, userId, cb) => {
	return async (dispatch) => {
		dispatch({
			type: "SET_NARRATOR_REQUEST",
			guideId
		});
		return api.guides
			.setNarrator(publisherId, teachingMaterialId,articleId, guideId, userId)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_NARRATOR_SUCCESS",
					param: "publisher",
					publisherId,
					teachingMaterialId,
					guideId,
					userId
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => dispatch(getArticle(publisherId, teachingMaterialId, articleId)))
			.catch((err) => {
				logger.error(err, "Failed to set narrator");
				return Promise.reject(err);
			});
	};
};

export const setDisableState = (
	publisher,
	teachingMaterial,
	article,
	refetch,
	cb
) => {
	return async (dispatch) => {
		dispatch({
			type: "SET_DISABLE_STATE_REQUEST",
			article
		});
		return api.articles
			.disable(publisher, teachingMaterial, article)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_DISABLE_STATE_SUCCESS",
					param: "publisher",
					publisher,
					teachingMaterial,
					article,
					state: constants.ARTICLE_STATE.Disabled
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => {
				if (refetch) {
					dispatch(getArticle(publisher, teachingMaterial, article));
				}
				return null;
			})
			.catch((err) => {
				logger.error(err, "Failed to disable article");
				return Promise.reject(err);
			});
	};
};

export const setGuideDisableState = (
	publisherId,
	teachingMaterialId,
	articleId,
	guideId,
	refetch,
	cb
) => {
	return async (dispatch) => {
		dispatch({
			type: "SET_DISABLE_STATE_REQUEST",
			articleId
		});
		return api.guides
			.disableGuide(publisherId, teachingMaterialId, articleId, guideId)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_DISABLE_STATE_SUCCESS",
					param: "publisher",
					publisherId,
					teachingMaterialId,
					articleId,
					state: constants.GUIDE_STATE.Disabled
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => {
				if (refetch) {
					dispatch(getArticle(publisherId, teachingMaterialId, articleId));
				}
				return null;
			})
			.catch((err) => {
				logger.error(err, "Failed to disable guide");
				return Promise.reject(err);
			});
	};
};

export const setGuideEnableState = (
	publisherId,
	teachingMaterialId,
	articleId,
	guideId,
	refetch,
	cb
) => {
	return async (dispatch) => {
		dispatch({
			type: "SET_ENABLE_STATE_REQUEST",
			articleId
		});
		return api.guides
			.enableGuide(publisherId, teachingMaterialId, articleId, guideId)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_ENABLE_STATE_SUCCESS",
					param: "publisher",
					publisherId,
					teachingMaterialId,
					articleId,
					state: constants.GUIDE_STATE.New
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => {
				if (refetch) {
					dispatch(getArticle(publisherId, teachingMaterialId, articleId));
				}
				return null;
			})
			.catch((err) => {
				logger.error(err, "Failed to disable guide");
				return Promise.reject(err);
			});
	};
};

export const setEnableState = (publisher, teachingMaterial, article, cb) => {
	return async (dispatch) => {
		dispatch({
			type: "SET_ENABLE_STATE_REQUEST",
			article
		});
		return api.articles
			.enable(publisher, teachingMaterial, article)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_ENABLE_STATE_SUCCESS",
					param: "publisher",
					publisher,
					teachingMaterial,
					article,
					state: constants.ARTICLE_STATE.New
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => dispatch(getArticle(publisher, teachingMaterial, article)))
			.catch((err) => {
				logger.error(err, "Failed to enable article");
				return Promise.reject(err);
			});
	};
};

export const setGuidesDisable = (
	publisher,
	teachingMaterial,
	article,
	refetch,
	cb
) => {
	return async (dispatch) => {
		dispatch({
			type: "SET_GUIDES_DISABLE_REQUEST",
			article
		});
		return api.articles
			.disableGuides(publisher, teachingMaterial, article)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_GUIDES_DISABLE_SUCCESS"
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => {
				if (refetch) {
					dispatch(getArticle(publisher, teachingMaterial, article));
				}
				return null;
			})
			.catch((err) => {
				logger.error(err, "Failed to disable guide");
				return Promise.reject(err);
			});
	};
};

export const setGuidesEnable = (
	publisher,
	teachingMaterial,
	article,
	refetch,
	cb
) => {
	return async (dispatch) => {
		dispatch({
			type: "SET_GUIDES_ENABLE_REQUEST",
			article
		});
		return api.articles
			.enableGuides(publisher, teachingMaterial, article)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_GUIDES_ENABLE_SUCCESS"
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => {
				if (refetch) {
					dispatch(getArticle(publisher, teachingMaterial, article));
				}
				return null;
			})
			.catch((err) => {
				logger.error(err, "Failed to disable guides");
				return Promise.reject(err);
			});
	};
};

export const setMaterialDisableState = (publisher, teachingMaterialId, cb) => {
	return async (dispatch) => {
		dispatch({
			type: "SET_MATERIAL_DISABLE_STATE_REQUEST",
			teachingMaterialId
		});
		return api.teachingMaterials
			.disable(publisher, teachingMaterialId)
			.then((data) => {
				if (errorHandler(dispatch, data)) {
					return Promise.reject(data.errors);
				}
				dispatch({
					type: "SET_MATERIAL_DISABLE_STATE_SUCCESS",
					param: "publisher",
					publisher,
					teachingMaterialId
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => dispatch(getArticles(publisher, teachingMaterialId)))
			.catch((err) => {
				logger.error(err, "Failed to disable material");
				return Promise.reject(err);
			});
	};
};

export const disableAllNewArticles = () => {
	return async (dispatch) => {
		return api.teachingMaterials
			.disableAllNewArticles()
			.then((data) => {
				if (errorHandler(dispatch, data)) {
					return Promise.reject(data.errors);
				}
				return data;
			})
			.catch((err) => {
				logger.error(err, "Failed to disable new articles");
				return Promise.reject(err);
			});
	};
};

export const setMaterialEnableState = (publisher, teachingMaterialId, cb) => {
	return async (dispatch) => {
		dispatch({
			type: "SET_MATERIAL_ENABLE_STATE_REQUEST",
			teachingMaterialId
		});
		return api.teachingMaterials
			.enable(publisher, teachingMaterialId)
			.then((data) => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_MATERIAL_ENABLE_STATE_SUCCESS",
					param: "publisher",
					publisher,
					teachingMaterialId
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.then(() => dispatch(getArticles(publisher, teachingMaterialId)))
			.catch((err) => {
				logger.error(err, "Failed to enable material");
				return Promise.reject(err);
			});
	};
};
