import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router"; // react-router v4

import PropTypes from "prop-types";
import Articles from "../articles";
import Guides from "../guides";
import Loading from "../loading";
import Header from "../header";
import Publishers from "../publishers";
import TeachingMaterials from "../teachingMaterials";
import FileExports from "../fileExports";
import { connector } from "../../store";
import { isAdminOrNarratorOrProofListener } from "../../helpers/authHelpers";

class Routing extends Component {
	static propTypes = {
		authState: PropTypes.string,
		user: PropTypes.object.isRequired,
		isLoading: PropTypes.object.isRequired,
		action: PropTypes.shape({
			getUser: PropTypes.func.isRequired,
			updatePath: PropTypes.func.isRequired
		}).isRequired
	};

	static defaultProps = {
		authState: null,
		isLoading: {
			users: false,
			publishers: false,
			teachingMaterials: false,
			articles: false
		}
	};

	shouldComponentUpdate(nextProps, nextState) {
		return (
			nextProps.authState !== this.props.authState ||
			nextProps.user.email !== this.props.user.email
		);
	}

	render() {
		const { isLoading, authState, user } = this.props;
		if (
			isLoading.users ||
			isLoading.publishers ||
			isLoading.teachingMaterials ||
			isLoading.articles
		) {
			return <Loading />;
		}

		return authState === "signedIn" && user.email && user.email !== "" ? (
			<div>
				<Header />
				<Switch>
					<Route exact path="/" component={Publishers} />
					<Route
						exact
						path="/publishers/:publisherId"
						component={TeachingMaterials}
					/>
					<Route
						exact
						path="/publishers/:publisherId/:teachingMaterialId"
						component={Articles}
					/>
					<Route
						exact
						path="/publishers/:publisherId/:teachingMaterialId/guides"
						component={Guides}
					/>
					<Route
						exact
						path="/publishers/:publisherId/:teachingMaterialId/:articleId"
						component={Guides}
					/>
					{isAdminOrNarratorOrProofListener(user) && (
						<Route exact path="/export" component={FileExports} />
					)}
					<Redirect from="*" to="/" />
				</Switch>
			</div>
		) : null;
	}
}

export default connector(Routing, (props) => ({
	user: props.user,
	isLoading: props.isLoading,
	authState: props.authState,
	action: {
		getUser: props.action.getUser,
		updatePath: props.action.updatePath
	}
}));
