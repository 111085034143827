const guidesReducer = (state = null, action) => {
    switch (action.type) {
        case "SET_GUIDES":
            return action.data || state;

        case "CLEAR_DATA":
            return null;

        default:
            return state;
    }
};
export default guidesReducer;
