/* eslint-disable no-script-url */
import React, { Component } from "react";
import Alert from "antd/lib/alert";

export default class FrontendBookmark extends Component {
	componentDidMount() {
		const getBaseUrl = function () {
			return process.env.REACT_APP_ILT_FRONTEND_URL || null;
		};

		const linkScript = `
			var ts = Math.floor(Date.now()/1000); 
			var scriptTag = document.createElement('script'); 
			scriptTag.src='${getBaseUrl()}/main.bundle.js?ts='+ts; 
			document.body.appendChild(scriptTag); 
			
			var s=document.createElement('link'); 
			s.setAttribute('href','${getBaseUrl()}/main.bundle.css?ts='+ts); 
			s.setAttribute('rel','stylesheet'); s.setAttribute('type','text/css'); 
			document.getElementsByTagName('head')[0].appendChild(s);
		`;

		const link = document.createElement("a");
		link.setAttribute("href", "javascript:(function(){" + linkScript + "})();");

		const env = process.env.REACT_APP_ENV || "unknown";
		const linkTitle = env.startsWith("prod")
			? "DTMN Recorder"
			: "DTMN Recorder (" + env + ")";

		link.appendChild(document.createTextNode(linkTitle));
		const target = document.getElementsByClassName("ant-alert-message")[0];
		if (target) {
			target.innerHTML = "";
			target.appendChild(link);
		}
	}

	render() {
		const descriptionElement = (
			<code>
				Add this link to bookmarks and trigger it while viewing an article -{" "}
				<br />
				or use the{" "}
				<a
					target="_blank"
					rel="noopener noreferrer"
					href="https://chrome.google.com/webstore/detail/ilt-narratorproof-listene/haagbkddjdljlkekahenadkhkglckgdl"
				>
					Chrome plugin
				</a>
			</code>
		);
		return (
			<div className="bookmark-container">
				<Alert
					message="DTMN Recorder"
					description={descriptionElement}
					type="info"
					showIcon
				/>
			</div>
		);
	}
}
