import React, { Component } from "react";
import Form from "antd/lib/form";
import Select from "antd/lib/select";
import PropTypes from "prop-types";

import { Button, Popconfirm, Tooltip, Input, Collapse } from 'antd'
import { UnorderedListOutlined } from '@ant-design/icons'
import message from 'antd/lib/message'
import * as constants from "../constants";
import { splitUsername } from "../helpers/authHelpers";
import * as logger from '../helpers/logger'
import { connector } from '../store'

const { Option } = Select;
const { Panel } = Collapse;

class EditMaterialForm extends Component {
	constructor(props) {
		super(props);
		const {modalMaterial} = props;
		this.state = {
			selectedNarrator: modalMaterial.DefaultNarratorId,
			selectedProofListener: modalMaterial.DefaultProofListenerId,
			guidesNarrators: [],
			guidesProofListeners: [],
			availableLanguages: [],
			selectedGuidesNarrator: [],
			selectedGuidesProofListeners: []

		};
	}

	async componentDidMount() {

		this._setSelectedGuidesNarratorsProofListeners()
		await this._getAllGuidesNarratorsProofListeners();
	}

	componentDidUpdate = (prevProps, prevState) => {
		const {modalMaterial} = this.props

		if (modalMaterial !== prevProps.modalMaterial){
			this._setSelectedNarratorProofListener();
			this._setSelectedGuidesNarratorsProofListeners();
		}

	}

	onReDetectBtnClick = () => {
		const { action, modalMaterial } = this.props;
		action
			.reDetectSentencesInMaterial(
				modalMaterial.Publisher,
				modalMaterial.Id,
			)
			.then((article) => {
				message.success(
					"Sentences in material have been re-detected, and a new version has been created"
				);

				return article;
			})
			.catch((err) => {
				logger.error(err, "Failed to re-detect sentences in material");
			});
	};

	_setSelectedNarratorProofListener = () => {
		const {modalMaterial} = this.props;

		this.setState({
			selectedNarrator: modalMaterial.DefaultNarratorId,
			selectedProofListener: modalMaterial.DefaultProofListenerId
		})
	}

	_setSelectedGuidesNarratorsProofListeners = () => {
		const {modalMaterial} = this.props;

		this.setState({
			selectedGuidesNarrator: modalMaterial.DefaultGuideNarratorsProofListeners?.DefaultGuideNarratorsByLanguage || [],
			selectedGuidesProofListeners: modalMaterial.DefaultGuideNarratorsProofListeners?.DefaultGuideProofListenersByLanguage || []
		})
	}

	_setSelectedGuideNarrator = (language, narrator) => {
		const {selectedGuidesNarrator} = this.state;
		selectedGuidesNarrator[language] = narrator;
		this.setState({
			selectedGuidesNarrator
		});
	}

	_setSelectedGuideProofListener = (language, proofListener) => {
		const {selectedGuidesProofListeners} = this.state;
		selectedGuidesProofListeners[language] = proofListener;
		this.setState({
			selectedGuidesProofListeners
		});
	}

	async _getAllGuidesNarratorsProofListeners() {
		const {action, users} = this.props;
		const availableLanguages = await action.getAllGuidesLanguages();
		const narrators = {};
		const proofListeners = {};

		availableLanguages.forEach((l) => {
			narrators[l] = users.find((u) => u.Name === "narrator-" + l.toLowerCase() ) || [];
			proofListeners[l] = users.find((u) => u.Name === "prooflistener-" + l.toLowerCase()) || [];
		})

		this.setState({
			guidesNarrators: narrators,
			guidesProofListeners: proofListeners,
			availableLanguages
		})

	}

	render() {
		const {
			users,
			onSetDefaultNarrator,
			onSetDefaultProofListener,
			onSetDefaultGuideNarrator,
			onSetDefaultGuideProofListener,
			showGuidesForPublisher,
			modalMaterial
		} = this.props;
		const {
			selectedNarrator,
			selectedProofListener,
			guidesNarrators,
			guidesProofListeners,
			availableLanguages,
			selectedGuidesNarrator,
			selectedGuidesProofListeners
		} = this.state;

		const narratorGroup = users.find((u) => u.Name === "narrator") || [];
		const proofListenerGroup =
			users.find((u) => u.Name === "prooflistener") || [];

		return (
			<div className="teaching-material-form">
				<Form layout="vertical">
					<Form.Item
						label="Default narrator"
						layout="vertical"
						rules={[{ required: false, message: "Please select user" }]}
					>
						<Select
							placeholder="Select"
							defaultValue={selectedNarrator}
							onChange={(val) => {
								this.setState({
									selectedNarrator: val
								});
							}}
						>
							<Option key="-1" value={constants.GUID_EMPTY}>
								No default set
							</Option>
							{narratorGroup.Users &&
								narratorGroup.Users.map((u, i) => (
									<Option key={i} value={u.Id}>
										{splitUsername(u.Name)}
									</Option>
								))}
						</Select>
						<Button
							type="primary"
							onClick={() =>
								onSetDefaultNarrator(this.state.selectedNarrator, false)
							}
							size="small"
							style={{ marginRight: 8, marginTop: 10 }}
						>
							Set for unassigned
						</Button>
						<Button
							type="danger"
							onClick={() =>
								onSetDefaultNarrator(this.state.selectedNarrator, true)
							}
							size="small"
							style={{ marginRight: 8, marginTop: 10 }}
						>
							Set for unpublished articles
						</Button>
					</Form.Item>

					<Form.Item
						label="Default proof listener"
						rules={[{ required: false, message: "Please select user" }]}
					>
						<Select
							placeholder="Select"
							defaultValue={selectedProofListener}
							onChange={(val) => {
								this.setState({
									selectedProofListener: val
								});
							}}
						>
							<Option key="-1" value={constants.GUID_EMPTY}>
								No default set
							</Option>
							{proofListenerGroup.Users &&
								proofListenerGroup.Users.map((u, i) => (
									<Option key={i} value={u.Id}>
										{splitUsername(u.Name)}
									</Option>
								))}
						</Select>
						<Button
							type="primary"
							onClick={() =>
								onSetDefaultProofListener(
									this.state.selectedProofListener,
									false
								)
							}
							size="small"
							style={{ marginRight: 8, marginTop: 10 }}
						>
							Set for unassigned
						</Button>
						<Button
							type="danger"
							onClick={() =>
								onSetDefaultProofListener(
									this.state.selectedProofListener,
									true
								)
							}
							size="small"
							style={{ marginRight: 8, marginTop: 10 }}
						>
							Set and overwrite all
						</Button>
					</Form.Item>
				</Form>
				<Form layout="vertical" className='guides-assigning'
					  style={{display: showGuidesForPublisher ? "block" : "none"}}>
					<h2>Guides</h2>
					<Collapse>
						{availableLanguages.map((group) =>
							<Panel header={group} key={group}>
								<Form.Item
									key={group}
									className="guide-language-group"
									rules={[{required: false}]}
								>
									<Input.Group compact>
										<Form.Item label="Narrator: ">
											<Select
												placeholder="Select"

												defaultValue={selectedGuidesNarrator[group.toLowerCase()]}
												onChange={(val) => {
													this._setSelectedGuideNarrator(group, val);
												}}
											>
												<Option key="-1" value={constants.GUID_EMPTY}>No default set</Option>
												{guidesNarrators[group].Users && guidesNarrators[group].Users.map((u, i) => (
													<Option key={i} value={u.Id}>
														{splitUsername(u.Name)}
													</Option>
												))}
											</Select>
											<Button
												type="primary"
												onClick={() => onSetDefaultGuideNarrator(
													selectedGuidesNarrator[group],
													group,
													false
												)}
												size="small"
												style={{marginRight: 8, marginTop: 10}}
											>
												Set for not processed
											</Button>
											<Button
												type="danger"
												onClick={() => onSetDefaultGuideNarrator(
													selectedGuidesNarrator[group],
													group,
													true
												)}
												size="small"
												style={{marginRight: 8, marginTop: 10}}
											>
												Set for unpublished
											</Button>
										</Form.Item>
										<Form.Item label="Proof listener: ">
											<Select
												placeholder="Select"
												defaultValue={selectedGuidesProofListeners[group.toLowerCase()]}
												onChange={(val) => {
													this._setSelectedGuideProofListener(group, val);
												}}
											>
												<Option key="-1" value={constants.GUID_EMPTY}>No default set</Option>
												{guidesProofListeners[group].Users && guidesProofListeners[group].Users.map((u, i) => (
													<Option key={i} value={u.Id}>
														{splitUsername(u.Name)}
													</Option>
												))}
											</Select>
											<Button
												type="primary"
												onClick={() => onSetDefaultGuideProofListener(
													selectedGuidesProofListeners[group],
													group,
													false
												)}
												size="small"
												style={{marginRight: 8, marginTop: 10}}
											>
												Set for unassigned
											</Button>
											<Button
												type="danger"
												onClick={() => onSetDefaultGuideProofListener(
													selectedGuidesProofListeners[group],
													group,
													true
												)}
												size="small"
												style={{marginRight: 8, marginTop: 10}}
											>
												Set and overwrite all
											</Button>
										</Form.Item>

									</Input.Group>
								</Form.Item>
							</Panel>
						)}
					</Collapse>
				</Form>
				<Tooltip title="This is redetecting in all articles in the teaching material.">
					<Popconfirm
						title="Are you sure you want to re-detect sentences in this article? This might cause breaking changes if recorded."
						onConfirm={() =>
							this.onReDetectBtnClick(modalMaterial.Id)
						}
						okText="Yes"
						cancelText="No"
					>
						<Button key="redetect" type="danger">
							<UnorderedListOutlined />
							Redetect sentences in all articles
						</Button>
					</Popconfirm>
				</Tooltip>
			</div>
		);
	}
}

EditMaterialForm.propTypes = {
	users: PropTypes.arrayOf(PropTypes.object),
	modalMaterial: PropTypes.object,
	onSetDefaultNarrator: PropTypes.func,
	onSetDefaultProofListener: PropTypes.func,
	onSetDefaultGuideNarrator: PropTypes.func.isRequired,
	onSetDefaultGuideProofListener: PropTypes.func.isRequired,
	showGuidesForPublisher: PropTypes.bool.isRequired,
	action: PropTypes.shape({
		reDetectSentencesInMaterial: PropTypes.func.isRequired,
		getAllGuidesLanguages: PropTypes.func.isRequired
	}).isRequired
};
export default connector(EditMaterialForm, (props) => ({
	action: {
		reDetectSentencesInMaterial: props.action.reDetectSentencesInMaterial,
		getAllGuidesLanguages: props.action.getAllGuidesLanguages
	}
}));