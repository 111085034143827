import { splitUsername } from './authHelpers'
import * as constants from '../constants'

export const isEmpty = (val) => {
	return (
		!val ||
		val === null ||
		val === undefined ||
		val === "null" ||
		val === "undefined" ||
		val === "NaN" ||
		val === "" ||
		val === {}
	);
};
export const formatDateTime = (datetime = Date()) => {
	if (!datetime) {
		return "Unknown";
	}
	if (!(datetime instanceof Date)) {
		datetime = new Date(datetime);
	}
	return (
		datetime.getUTCFullYear() +
		"-" +
		("0" + (datetime.getUTCMonth() + 1)).slice(-2) +
		"-" +
		("0" + datetime.getUTCDate()).slice(-2) +
		" " +
		("0" + datetime.getUTCHours()).slice(-2) +
		":" +
		("0" + datetime.getUTCMinutes()).slice(-2) +
		":" +
		("0" + datetime.getUTCSeconds()).slice(-2)
	);
};

export const callDebugger = (loop, ...argumentss) => {
	if (!loop && typeof window.debugHadStarted !== 'undefined' && window.debugHadStarted === true) {
		return;
	}
	window.debugHadStarted = true
	debugger;
}

export const findUserById = (users, id) => {
	const user  = users.find((u) => u.Id === id);
	if(user){
		return user.Name;
	}

	return 'unassigned'
}

export const getAssignedPeople = (users, assignedPeople) => {
	const assignedPeopleList = {
		Narrators: [],
		Prooflisteners: []
	}
	let user;
	const narrators = users.find((u) => u.Name === 'narrator').Users || []
	const proflisteners = users.find((u) => u.Name === 'prooflistener').Users || []

	Object.keys(assignedPeople).forEach((id) => {
		user = narrators.find((u) => u.Id === id);
		if(user && constants.GUID_EMPTY !== id){
			assignedPeopleList.Narrators.push({name:splitUsername(user.Name), Id: id})
		}
		user = proflisteners.find((u) => u.Id === id);
		if(user && constants.GUID_EMPTY !== id){
			assignedPeopleList.Prooflisteners.push({name:splitUsername(user.Name), Id: id})
		}
	})
	if(assignedPeopleList.Narrators.length === 0){
		assignedPeopleList.Narrators.push({name:'Unassigned', Id: constants.GUID_EMPTY})
	}
	if(assignedPeopleList.Prooflisteners.length === 0){
		assignedPeopleList.Prooflisteners.push({name:'Unassigned', Id: constants.GUID_EMPTY})
	}

	return assignedPeopleList;
}

export const isArticle = (entity) => {
	return typeof entity.TeachingMaterialId === "undefined";
}