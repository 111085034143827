export const zero = (n) => Number(n) < 10 ? `0${n}` : n

const secondsToTimeFormat = timeSeconds => {
  let time = timeSeconds 
  
  let hours = "00"
  let minutes = "00"
  let seconds = "00"
  
  if (time => 3600) {
    hours = parseInt(time / 3600)
    time = time - (hours * 3600)
  }

  if (time <= 3599) {
    minutes = parseInt(time / 60)
    time = time - minutes * 60
  }

  seconds = time

  return `${zero(hours)}:${zero(minutes)}:${zero(seconds)}`
}


export default secondsToTimeFormat