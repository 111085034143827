import React, { Component } from "react";
import api from "../../api";
import secondsToTimeFormat from "../../helpers/secondsToTimeFormat";
import * as logger from "../../helpers/logger";

class SummarizedWorked extends Component {
	constructor() {
		super();
		this.state = { worked: 0, fetching: false };
	}

	componentDidMount() {
		this.getWorklog();
	}

	componentDidUpdate(prevProps, prevState) {
		const { from, to } = this.props;
		if (prevProps.from !== from || prevProps.to !== to) {
			this.getWorklog();
			return false;
		} else {
			return false;
		}
	}

	getWorklog() {
		const { from, to } = this.props;
		this.setState({ fetching: true });
		api.fileExports
			.getWorklog(from, to, true)
			.then((worklog) => {
				if (worklog.length === -1) return false;
				if (worklog.count === 0) return false;

				const worked = worklog
					.map((entry) => {
						const time = entry.TimeWorked.split(":");
						const seconds =
							Number(time[2]) + Number(time[1]) * 60 + Number(time[0]) * 3600;
						return seconds;
					})
					.reduce((a, b) => Number(a) + Number(b), 0); // total

				this.setState({ worked, fetching: false });
				return worklog;
			})
			.catch((err) => {
				logger.error(err, "Failed to get work log data");
			});
	}

	render() {
		const { worked, fetching } = this.state;
		const { from, to } = this.props;
		return (
			<span>
				Total remunerable time between {from} and {to}:{" "}
				{fetching ? (
					"Calculating, please wait..."
				) : (
					<strong>{secondsToTimeFormat(worked)}</strong>
				)}
			</span>
		);
	}
}

export default SummarizedWorked;
