import React from "react";

import { Table } from "antd";
import PropTypes from "prop-types";

import * as constants from "../constants";
import StepProgressBar from "../components/stepProgressBar";
import { getUserName } from "../helpers/authHelpers";
import { formatDateTime } from "../helpers/miscHelpers";

const ArticleInfoForm = (props) => {
	const { article, users } = props;
	return (
		<div className="teaching-material-form">
			<StepProgressBar article={article} />
			<Table
				rowKey="Id"
				columns={LogColumns(users)}
				dataSource={article.LogEntries}
				pagination={false}
			/>
		</div>
	);
};

function LogColumns(users) {
	return [
		{
			title: "Date",
			dataIndex: "Created",
			render: (id, row) => <p> {formatDateTime(row.Created)} </p>,
			width: 20
		},
		{
			title: "Type",
			dataIndex: "LogEntryType",
			render: (id, row) => <p> {LogStateText(row)} </p>,
			width: 80
		},
		{
			title: "User",
			dataIndex: "UserId",
			render: (id, row) => <p> {getUserName(row.UserId, users)} </p>,
			width: 60
		},
		{
			title: "Additional text",
			dataIndex: "Text",
			render: (id, row) => <p> {FormatText(row, users)} </p>,
			width: 100
		}
	];
}

const FormatText = (row, users) => {
	const regex = /(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}/i;
	const found = row.Text.match(regex);
	let result = row.Text;
	if (found) {
		found.forEach(function (userId) {
			const username = getUserName(userId, users);
			if (username) {
				result = result.replace(userId, username);
			}
		});
	}
	return result;
};

const LogStateText = (row) => {
	return constants.LOG_ENTRY_TEXT[row.LogEntryType];
};

ArticleInfoForm.propTypes = {
	article: PropTypes.object.isRequired,
	users: PropTypes.arrayOf(PropTypes.object),
	component: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired
};
export default ArticleInfoForm;
