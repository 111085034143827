import { history } from "../store";
import * as Util from "./miscHelpers";

const getQuerystringParam = (key, defaultValue) => {
	const urlParams = new URLSearchParams(window.location.search);
	return urlParams.get(key) || defaultValue || null;
};

const updateQueryString = (pathname, page, filters) => {
	const querystring =
		"?page=" +
		page +
		Object.keys(filters)
			.map((p) =>
				Util.isEmpty(filters[p]) ? "" : `&filter-${p}=${filters[p]}`
			)
			.join("");

	if (window.location.search !== querystring) {
		history.replace({
			pathname: pathname,
			search: querystring
		});
	}
};

export { getQuerystringParam, updateQueryString };
