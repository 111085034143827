export const GUID_EMPTY = "00000000-0000-0000-0000-000000000000";

export const SENTENCE_STATE = Object.freeze({
	Undefined: 0,
	NotRecorded: 1,
	Recorded: 2,
	Changed: 3,
	Disabled: 4
});

export const USER_ROLE = Object.freeze({
	Undefined: 0,
	Admin: 100,
	Narrator: 200,
	ProofListener: 300,
	Student: 400
});

export const USER_ROLE_NAME = Object.freeze({
	narrator: "narrator",
	prooflistener: "prooflistener"
});

export const LOG_ENTRY_TYPE = Object.freeze({
	Unknown: 0,
	SentenceStateChanged: 1,
	ArticleStateChanged: 2,
	SentenceRecorded: 3,
	RemarkUpdated: 4,
	ArticleUpdated: 5,
	ArticleNarratorChanged: 6,
	ArticleProofListenerChanged: 7,
	ArticleCreated: 8,
	AudioProcessingFailed: 9,
	ArticleReset: 10,
	SentenceOrderChanged: 11,
	SentenceUpdated: 12,
	SentencesRedetected: 13,
	ArticleRemovedByPublisher: 14,
	ArticleRestoredByPublisher: 15
});

export const LOG_ENTRY_TEXT = Object.freeze({
	[LOG_ENTRY_TYPE.Unknown]: "Unknown",
	[LOG_ENTRY_TYPE.SentenceStateChanged]: "State changed",
	[LOG_ENTRY_TYPE.ArticleStateChanged]: "State changed",
	[LOG_ENTRY_TYPE.SentenceRecorded]: "Was recorded",
	[LOG_ENTRY_TYPE.RemarkUpdated]: "Remark updated",
	[LOG_ENTRY_TYPE.ArticleUpdated]: "Was updated",
	[LOG_ENTRY_TYPE.ArticleNarratorChanged]: "Narrator changed",
	[LOG_ENTRY_TYPE.ArticleProofListenerChanged]: "Proof listener changed",
	[LOG_ENTRY_TYPE.ArticleCreated]: "Was created",
	[LOG_ENTRY_TYPE.AudioProcessingFailed]: "Audio processing failed",
	[LOG_ENTRY_TYPE.ArticleReset]: "Was reset",
	[LOG_ENTRY_TYPE.SentenceOrderChanged]: "Sentence order changed",
	[LOG_ENTRY_TYPE.SentenceUpdated]: "Sentence was updated",
	[LOG_ENTRY_TYPE.SentencesRedetected]: "Sentences re-detected",
	[LOG_ENTRY_TYPE.ArticleRemovedByPublisher]:
		"Was removed/disabled by publisher",
	[LOG_ENTRY_TYPE.ArticleRestoredByPublisher]: "Was restored by publisher"
});

export const COLORS = Object.freeze({
	orange: "#ffd480",
	red: "#ff8a90",
	gray: "#b7b7b7",
	green: "#b4ef98",
	yellow: "#fdfd5d",
	blue: "#00a1c9",
	black: "#000",
	white: "#fff"
});

export const ARTICLE_STATE = Object.freeze({
	Undefined: 0,
	New: 1,
	Recording: 2,
	ProofListening: 3,
	CorrectionsNeeded: 4,
	ReadyForAudioProcessing: 5,
	ProcessingAudio: 9,
	ReadyForPublishing: 6,
	Published: 7,
	RecordingChanges: 8,
	Disabled: 10
});

export const GUIDE_STATE = Object.freeze({
	Undefined: 0,
	New: 1,
	Manuscripting:2,
	Recording: 3,
	ProofListening: 4,
	CorrectionsNeeded: 5,
	ReadyForAudioProcessing: 6,
	ProcessingAudio: 9,
	ReadyForPublishing: 7,
	Published: 8,
	Disabled: 10
});

export const ARTICLE_STATE_NAME = Object.keys(ARTICLE_STATE).reduce(function (
	obj,
	key
) {
	obj[ARTICLE_STATE[key]] = key;
	return obj;
},
{});

export const ARTICLE_STATE_TEXT = Object.freeze({
	[ARTICLE_STATE.Undefined]: "Unknown",
	[ARTICLE_STATE.New]: "New",
	[ARTICLE_STATE.Recording]: "Recording",
	[ARTICLE_STATE.ProofListening]: "Proof listening",
	[ARTICLE_STATE.CorrectionsNeeded]: "Corrections needed",
	[ARTICLE_STATE.ReadyForAudioProcessing]: "Ready for audio processing",
	[ARTICLE_STATE.ProcessingAudio]: "Processing audio",
	[ARTICLE_STATE.ReadyForPublishing]: "Ready for publishing",
	[ARTICLE_STATE.Published]: "Published",
	[ARTICLE_STATE.RecordingChanges]: "Recording changes",
	[ARTICLE_STATE.Disabled]: "Disabled"
})
;export const GUIDE_STATE_TEXT = Object.freeze({
	[GUIDE_STATE.Undefined]: "Unknown",
	[GUIDE_STATE.New]: "New",
	[GUIDE_STATE.Manuscripting]: "Manuscripting",
	[GUIDE_STATE.Recording]: "Recording",
	[GUIDE_STATE.ProofListening]: "Proof listening",
	[GUIDE_STATE.CorrectionsNeeded]: "Corrections needed",
	[GUIDE_STATE.ReadyForAudioProcessing]: "Ready for audio processing",
	[GUIDE_STATE.ProcessingAudio]: "Processing audio",
	[GUIDE_STATE.ReadyForPublishing]: "Ready for publishing",
	[GUIDE_STATE.Published]: "Published",
	[GUIDE_STATE.Disabled]: "Disabled"
});

export const ARTICLE_STATE_COLORS = Object.freeze({
	[ARTICLE_STATE.Undefined]: COLORS.gray,
	[ARTICLE_STATE.New]: COLORS.blue,
	[ARTICLE_STATE.Recording]: COLORS.yellow,
	[ARTICLE_STATE.ProofListening]: COLORS.yellow,
	[ARTICLE_STATE.CorrectionsNeeded]: COLORS.yellow,
	[ARTICLE_STATE.ReadyForAudioProcessing]: COLORS.yellow,
	[ARTICLE_STATE.ProcessingAudio]: COLORS.yellow,
	[ARTICLE_STATE.ReadyForPublishing]: COLORS.green,
	[ARTICLE_STATE.Published]: COLORS.green,
	[ARTICLE_STATE.RecordingChanges]: COLORS.orange,
	[ARTICLE_STATE.Disabled]: COLORS.red
});
export const GUIDE_STATE_COLORS = Object.freeze({
	[GUIDE_STATE.Undefined]: COLORS.gray,
	[GUIDE_STATE.New]: COLORS.blue,
	[GUIDE_STATE.Recording]: COLORS.yellow,
	[GUIDE_STATE.ProofListening]: COLORS.yellow,
	[GUIDE_STATE.CorrectionsNeeded]: COLORS.yellow,
	[GUIDE_STATE.ReadyForAudioProcessing]: COLORS.yellow,
	[GUIDE_STATE.ProcessingAudio]: COLORS.yellow,
	[GUIDE_STATE.ReadyForPublishing]: COLORS.green,
	[GUIDE_STATE.Published]: COLORS.green,
	[GUIDE_STATE.Manuscripting]: COLORS.yellow,
	[GUIDE_STATE.Disabled]: COLORS.red
});

export const ARTICLE_STATE_TEXT_COLORS = Object.freeze({
	[ARTICLE_STATE.Undefined]: COLORS.black,
	[ARTICLE_STATE.New]: COLORS.white,
	[ARTICLE_STATE.Recording]: COLORS.black,
	[ARTICLE_STATE.ProofListening]: COLORS.black,
	[ARTICLE_STATE.CorrectionsNeeded]: COLORS.black,
	[ARTICLE_STATE.ReadyForAudioProcessing]: COLORS.black,
	[ARTICLE_STATE.ProcessingAudio]: COLORS.black,
	[ARTICLE_STATE.ReadyForPublishing]: COLORS.black,
	[ARTICLE_STATE.Published]: COLORS.black,
	[ARTICLE_STATE.RecordingChanges]: COLORS.black,
	[ARTICLE_STATE.Disabled]: COLORS.black
});
