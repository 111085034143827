const loadingReducer = (state = {}, action) => {
	switch (action.type) {
		case "FETCHING_PUBLISHERS":
			return { ...state, publishers: true };
		case "SET_PUBLISHERS":
			return { ...state, publishers: false };
		case "SET_TEACHING_MATERIALS_REQUEST":
			return { ...state, teachingMaterials: true };
		case "SET_TEACHING_MATERIALS":
			return { ...state, teachingMaterials: false };
		case "FETCHING_ARTICLES":
			return { ...state, articles: true };
		case "FETCHING_GUIDES":
			return { ...state, guides: true };
		case "SET_GUIDES":
			return { ...state, guides: false };
		case "SET_ARTICLES":
			return { ...state, articles: false };
		case "REDETECT_MATERIAL_REQUEST":
			return { ...state, reDetectSentences: true };
		case "REDETECT_MATERIAL_SUCCESS":
			return { ...state, reDetectSentences: false };
		case "FETCHING_USERS":
			return { ...state, users: true };
		case "SET_USERS":
			return { ...state, users: false };
		case "SET_NARRATOR_REQUEST":
			return { ...state, article: action.article, assigningNarrator: true };
		case "SET_NARRATOR_SUCCESS":
			return { ...state, article: action.article, assigningNarrator: false };
		case "SET_PROOF_LISTENER_REQUEST":
			return {
				...state,
				article: action.article,
				assigningProofListener: true
			};
		case "SET_PROOF_LISTENER_SUCCESS":
			return {
				...state,
				article: action.article,
				assigningProofListener: false
			};
		case "PUBLISH_ARTICLE_REQUEST":
			return { ...state, article: action.article, publishing: true };
		case "UNPUBLISH_ARTICLE_REQUEST":
			return { ...state, article: action.article, unpublishing: true };
		case "PUBLISH_ARTICLE_SUCCESS":
			return { ...state, article: action.article, publishing: false };
		case "UNPUBLISH_ARTICLE_SUCCESS":
			return { ...state, article: action.article, unpublishing: false };
		case "SET_ENABLE_STATE_REQUEST":
			return { ...state, article: action.article, enable: true };
		case "SET_ENABLE_STATE_SUCCESS":
			return { ...state, article: action.article, enable: false };
		case "SET_DISABLE_STATE_REQUEST":
			return { ...state, article: action.article, disable: true };
		case "SET_DISABLE_STATE_SUCCESS":
			return { ...state, article: action.article, disable: false };
		case "SET_MATERIAL_ENABLE_STATE_REQUEST":
			return {
				...state,
				teachingMaterialId: action.teachingMaterialId,
				enable: true
			};
		case "SET_MATERIAL_ENABLE_STATE_SUCCESS":
			return {
				...state,
				teachingMaterialId: action.teachingMaterialId,
				enable: false
			};
		case "SET_MATERIAL_DISABLE_STATE_REQUEST":
			return {
				...state,
				teachingMaterialId: action.teachingMaterialId,
				disable: true
			};
		case "SET_MATERIAL_DISABLE_STATE_SUCCESS":
			return {
				...state,
				teachingMaterialId: action.teachingMaterialId,
				disable: false
			};
		default:
			return state;
	}
};

export default loadingReducer;
