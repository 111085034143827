export const log = (...args) => {
	if (shouldLog()) {
		console.log(args.shift(), args);
	}
};
export const warn = (...args) => {
	if (shouldLog()) {
		console.warn(args.shift(), args);
	}
};
export const error = (...args) => {
	if (shouldLog()) {
		console.error(args.shift(), args);
	}
};
export const debug = (...args) => {
	if (shouldLog()) {
		console.debug(args.shift(), args);
	}
};

const shouldLog = () => {
	const env = process.env.REACT_APP_ENV || "";
	return window.console && !env.startsWith("prod");
};
