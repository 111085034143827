import { createStore, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import createRootReducer from "./reducers";

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
	const composeEnhancers =
		// eslint-disable-next-line no-underscore-dangle
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	const store = createStore(
		createRootReducer(history), // root reducer with router state
		preloadedState,
		composeEnhancers(
			applyMiddleware(
				routerMiddleware(history), // for dispatching history actions
				thunk
			)
		)
	);

	return store;
}
