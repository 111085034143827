import React, {Component} from "react";
import Table from "antd/lib/table";
import PropTypes from "prop-types";
import message from "antd/lib/message";
import {isAdmin, isGuideNarrator, isGuideProofListener} from "../../helpers/authHelpers";
import * as CommonColumns from "../commonColumns";
import * as Columns from "./columns";
import {connector} from "../../store";
import * as constants from "../../constants";

import "./index.css";
import * as logger from "../../helpers/logger";
import {getQuerystringParam} from "../../helpers/querystringHelpers";

function getColumns(user, users, articles, guides, component) {
    if (user.groups) {
        if (isAdmin(user)) {
            return [
                CommonColumns.indicatorColumn(constants.GUIDE_STATE, constants.GUIDE_STATE_TEXT, constants.GUIDE_STATE_COLORS),
                CommonColumns.statusColumn(component, guides, 'State', constants.GUIDE_STATE_TEXT),
                Columns.languageColumn(component),
                CommonColumns.idColumn(component),
                Columns.titleColumn(component),
                CommonColumns.updatedColumn(),
                Columns.selectNarratorColumn(users, guides, component),
                Columns.selectProofListenerColumn(users, guides, component),
                Columns.enableDisableColumn(component),
                Columns.articleGotChangedColumn(component),
            ];
        }
        if (isGuideNarrator(user)) {
            return [
                CommonColumns.indicatorColumn(constants.GUIDE_STATE, constants.GUIDE_STATE_TEXT, constants.GUIDE_STATE_COLORS),
                CommonColumns.statusColumn(component, guides, 'State', constants.GUIDE_STATE_TEXT),
                Columns.languageColumn(component),
                Columns.titleColumn(component),
                CommonColumns.updatedColumn(),
                Columns.articleGotChangedColumn(component),
            ];
        }
        if (isGuideProofListener(user)) {
            return [
                CommonColumns.indicatorColumn(constants.GUIDE_STATE, constants.GUIDE_STATE_TEXT, constants.GUIDE_STATE_COLORS),
                CommonColumns.statusColumn(component, guides, 'State', constants.GUIDE_STATE_TEXT),
                Columns.languageColumn(component),
                Columns.titleColumn(component),
                CommonColumns.updatedColumn(),
            ];
        }
    }
    return null;
}

class Guides extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: {
                status: null,
            },
            articles: [],
            guides: []
        };
    }

    async componentDidMount() {
        const {match, action} = this.props;
        this.publisherId = match.params.publisherId;
        this.teachingMaterialId = match.params.teachingMaterialId;
        this.articleId = match.params.articleId;

        await action.getUsersIfNeeded();
        await action.getPublishersIfNeeded();
        await action.getTeachingMaterialsIfNeeded(this.publisherId);
        await action.getArticlesIfNeeded(this.publisherId, this.teachingMaterialId);

        if (!this.articleId) {
            await action.getGuidesIfNeeded(this.publisherId, this.teachingMaterialId);
        }
        this._setArticles();
        this._setGuides();

        action.updatePath(window.location.pathname);

        this.parseQueryString();
    }

    componentWillUnmount() {
        this.setState({
                "articles": [],
                "guides": []
            }
        );
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {publishers} = this.props;
        const {articles} = this.state;
        if (prevProps.publishers !== publishers) {
            this._setArticles();
        } else if (prevState.articles !== articles) {
            this._setGuides();
        }
    };

    parseQueryString = () => {
        const {filters, page} = this.state;
        const newFilters = {};
        ["state", "title", "id", "narrator", "proof_listener"].forEach((p) => {
            newFilters[p] = getQuerystringParam("filter-" + p);
        });

        const newPage = parseInt(getQuerystringParam("page", 1));
        if (
            JSON.stringify(filters) !== JSON.stringify(newFilters) ||
            page !== newPage
        ) {
            this.setState({
                filters: newFilters,
                page: newPage
            });
        }
    };

    onStateBtnClick = (props, row) => {
        if (row.State !== constants.GUIDE_STATE.Disabled) {
            props.action
                .setGuideDisableState(
                    props.match.params.publisherId,
                    props.match.params.teachingMaterialId,
                    row.ArticleId,
                    row.Id,
                    isAdmin(props.user)
                )
                .then(() => {
                    if (!isAdmin(props.user)) {
                        props.action.removeArticle(
                            props.match.params.publisherId,
                            props.match.params.teachingMaterialId,
                            row.Id
                        );
                    }
                    message.success("Guide disabled successfully");
                    return null;
                })
                .catch((err) => {
                    logger.error(err, "Failed to disable guide");
                });
        } else {
            props.action
                .setGuideEnableState(
                    props.match.params.publisherId,
                    props.match.params.teachingMaterialId,
                    row.ArticleId,
                    row.Id,
                    row.Id
                )
                .then(() => {
                    message.success("Article enabled successfully");
                    return null;
                })
                .catch((err) => {
                    logger.error(err, "Failed to enable article");
                });
        }
    };

    onChangeNarratorOrProofListener = (props, userId) => {
        if (props.userType.includes("narrator")) {
            props.action
                .setGuideNarrator(
                    props.match.params.publisherId,
                    props.match.params.teachingMaterialId,
                    props.row.ArticleId,
                    props.row.Id,
                    userId
                )
                .then(() => {
                    message.success("Narrator updated successfully");
                    return null;
                })
                .catch((err) => {
                    logger.error(err, "Failed to assign narrator");
                });
        }

        if (props.userType.includes("prooflistener")) {
            props.action
                .setGuideProofListener(
                    props.match.params.publisherId,
                    props.match.params.teachingMaterialId,
                    props.row.ArticleId,
                    props.row.Id,
                    userId
                )
                .then(() => {
                    message.success("Proof listener updated successfully");
                    return null;
                })
                .catch((err) => {
                    logger.error(err, "Failed to assign proof listener");
                });
        }
    }

    _setArticles = () => {
        const {publishers, match} = this.props;

        const publisher = publishers?.find(
            (p) => p.Id === match.params.publisherId
        ) || {TeachingMaterials: []};
        const teachingMaterials = publisher.TeachingMaterials || [];
        const teachingMaterial = teachingMaterials.find(
            (t) => t.Id === match.params.teachingMaterialId
        ) || {Articles: []};
        const articles = teachingMaterial.Articles || [];

        if (articles.length > 0) {
            this.setState({"articles": articles});
        }
    }

    _setGuides = () => {
        const {match, guides} = this.props;
        const {articles} = this.state;
        if (this.articleId) {
            const article = articles.find(
                (a) => a.Id === match.params.articleId
            ) || {Guides: []};
            if (article.Guides.length > 0) {
                return this.setState({"guides": article.Guides});
            }

            return;
        }
        if (guides && guides.length > 0) {
            return this.setState({"guides": guides});
        }
    }

    getGuideArticle = (guide) => {
        const {articles} = this.state;
        return articles.find(
            (a) => a.Id === guide.ArticleId
        );
    }

    setSearchText = () => {
    }

    setSearchColumn = () => {
    }

    render() {
        const {isLoading, users, user} = this.props;
        const {articles, guides} = this.state;

        return (
            <div className="guides">
                <Table
                    rowKey="Id"
                    columns={getColumns(user, users, articles, guides, this)}
                    dataSource={guides}
                    loading={
                        isLoading.articles ||
                        isLoading.teachingMaterials ||
                        isLoading.publishers ||
                        isLoading.guides ||
                        isLoading.users
                    }
                    rowClassName={(r) => {
                        if (r.ArticleVersion !== this.getGuideArticle(r)?.Version) {
                            return "guides__row--breakingchanges";
                        }
                        if (r.State === constants.GUIDE_STATE.Published) {
                            return "guides__row--published";
                        }
                        if (r.State === constants.GUIDE_STATE.Disabled) {
                            return "guides__row--disabled";
                        }
                    }}
                />
            </div>
        );
    };
}

Guides.defaultProps = {
    users: [],
    publishers: [],
    guides: []
};

Guides.propTypes = {
    isLoading: PropTypes.object.isRequired,
    users: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.object.isRequired,
    publishers: PropTypes.arrayOf(PropTypes.object),
    guides: PropTypes.arrayOf(PropTypes.object),
    match: PropTypes.object.isRequired,
    action: PropTypes.shape({
        updatePath: PropTypes.func.isRequired,
        getUsersIfNeeded: PropTypes.func.isRequired,
        getPublishersIfNeeded: PropTypes.func.isRequired,
        getTeachingMaterialsIfNeeded: PropTypes.func.isRequired,
        getArticlesIfNeeded: PropTypes.func.isRequired,
        setGuideDisableState: PropTypes.func.isRequired,
        setGuideEnableState: PropTypes.func.isRequired,
        setGuideNarrator: PropTypes.func.isRequired,
        getGuidesIfNeeded: PropTypes.func.isRequired,
        setGuideProofListener: PropTypes.func.isRequired
    }).isRequired
};

export default connector(Guides, (props) => ({
    isLoading: props.isLoading,
    publishers: props.publishers,
    guides: props.guides,
    user: props.user,
    users: props.users,
    path: props.path,
    action: {
        updatePath: props.action.updatePath,
        getUsersIfNeeded: props.action.getUsersIfNeeded,
        getPublishersIfNeeded: props.action.getPublishersIfNeeded,
        getTeachingMaterialsIfNeeded: props.action.getTeachingMaterialsIfNeeded,
        getArticlesIfNeeded: props.action.getArticlesIfNeeded,
        setGuideDisableState: props.action.setGuideDisableState,
        setGuideEnableState: props.action.setGuideEnableState,
        setGuideNarrator: props.action.setGuideNarrator,
        setGuideProofListener: props.action.setGuideProofListener,
        getGuidesIfNeeded: props.action.getGuidesIfNeeded
    }
}));

