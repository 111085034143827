import React, { Component } from "react";
import Highlighter from "react-highlight-words";
import PropTypes from "prop-types";
import Table from "antd/lib/table";
import { SearchOutlined } from "@ant-design/icons";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import {
	getQuerystringParam,
	updateQueryString
} from "../../helpers/querystringHelpers";

import { Link } from "react-router-dom";
import { connector } from "../../store/index";

class Publishers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchText: ""
		};
	}

	async componentDidMount() {
		const { action } = this.props;
		await action.getUsersIfNeeded();
		await action.getPublishersIfNeeded();
		action.updatePath(window.location.pathname);

		this.parseQueryString();
		window.addEventListener("popstate", this.parseQueryString);
	}

	componentWillUnmount() {
		window.removeEventListener("popstate", this.parseQueryString);
	}

	parseQueryString = () => {
		const { filters, page } = this.state;
		const newFilters = {};
		["id"].forEach((p) => {
			newFilters[p] = getQuerystringParam("filter-" + p);
		});

		const newPage = parseInt(getQuerystringParam("page", 1));
		if (
			JSON.stringify(filters) !== JSON.stringify(newFilters) ||
			page !== newPage
		) {
			this.setState({
				filters: newFilters,
				page: newPage
			});
			updateQueryString(`/`, newPage, newFilters);
		}
	};

	getColumnSearchProps = (dataIndex) => {
		const { searchText } = this.state;

		return {
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters
			}) => (
				<div className="custom-filter-dropdown">
					<Input
						ref={(node) => {
							this.searchInput = node;
						}}
						placeholder={`Search ${dataIndex}`}
						value={selectedKeys[0]}
						onChange={(e) =>
							setSelectedKeys(e.target.value ? [e.target.value] : [])
						}
						onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
						style={{ width: 188, marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => this.handleSearch(selectedKeys, confirm)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90, marginRight: 8 }}
					>
						Search
					</Button>
					<Button
						onClick={() => this.handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						Reset
					</Button>
				</div>
			),
			filterIcon: (filtered) => (
				<SearchOutlined
					style={{ color: filtered ? "#1890ff" : undefined, display: "block" }}
				/>
			),
			onFilter: (value, record) =>
				record[dataIndex]
					.toString()
					.toLowerCase()
					.includes(value.toLowerCase()),
			onFilterDropdownVisibleChange: (visible) => {
				if (visible) {
					setTimeout(() => this.searchInput.select());
				}
			},
			render: (text) => (
				<Link to={`/publishers/${text}`}>
					<Highlighter
						highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
						searchWords={[searchText]}
						autoEscape
						textToHighlight={text.toString()}
					/>
				</Link>
			)
		};
	};

	handleSearch = (selectedKeys, confirm) => {
		confirm();
		this.setState({ searchText: selectedKeys[0] });
	};

	handleReset = (clearFilters) => {
		clearFilters();
		this.setState({ searchText: "" });
	};

	render() {
		const { isLoading, publishers } = this.props;
		return (
			<div>
				<Table
					rowKey="Id"
					columns={[
						{
							title: "Title",
							key: "Id",
							dataIndex: "Id",
							...this.getColumnSearchProps("Id")
						},
						{
							title: "Description",
							key: "Description",
							dataIndex: "Description"
						}
					]}
					dataSource={publishers}
					loading={isLoading.publishers}
				/>
			</div>
		);
	}
}

Publishers.propTypes = {
	publishers: PropTypes.arrayOf(PropTypes.object),
	isLoading: PropTypes.object.isRequired,
	action: PropTypes.shape({
		getUsersIfNeeded: PropTypes.func.isRequired,
		getPublishersIfNeeded: PropTypes.func.isRequired,
		updatePath: PropTypes.func.isRequired
	}).isRequired
};

Publishers.defaultProps = {
	publishers: []
};

export default connector(Publishers, (props) => ({
	publishers: props.publishers,
	isLoading: props.isLoading,
	action: {
		getUsersIfNeeded: props.action.getUsersIfNeeded,
		getPublishersIfNeeded: props.action.getPublishersIfNeeded,
		updatePath: props.action.updatePath
	}
}));
