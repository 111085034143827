import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import publisherReducer from "./publisherReducer";
import userReducer from "./userReducer";
import usersReducer from "./usersReducer";
import routeReducer from "./routeReducer";
import loadingReducer from "./loadingReducer";
import authStateReducer from "./authStateReducer";
import guidesReducer from "./guidesReducer";

export default history =>
	combineReducers({
		router: connectRouter(history),
		publishers: publisherReducer,
		user: userReducer,
		users: usersReducer,
		path: routeReducer,
		isLoading: loadingReducer,
		authState: authStateReducer,
		guides: guidesReducer
	});
