import React, { Component } from "react";
import DatePicker from "antd/lib/date-picker";
import Button from "antd/lib/button";
import Tooltip from "antd/lib/tooltip";
import Typography from "antd/lib/typography";
import moment from "moment";
import "moment/locale/sv";
import PropTypes from "prop-types";
import { Alert } from "antd";

import { DollarOutlined, LoadingOutlined } from "@ant-design/icons";

import api from "../../api";
import { connector } from "../../store";
import "./index.css";
import * as logger from "../../helpers/logger";

import SummarizedWork from "../SummarizedWork";
import { isAdmin, isNarrator } from "../../helpers/authHelpers";

const fileSaver = require("file-saver");

const { RangePicker } = DatePicker;
const { Title } = Typography;

class FileExports extends Component {
	constructor(props) {
		super(props);
		this.state = {
			from: undefined,
			to: undefined,
			worklogData: [],
			workedTimeToday: "00:00:00",
			workedTimeTodayFetched: "00:00"
		};
	}

	componentDidMount() {
		const from = moment();
		const to = moment().add(1, "days");
		this.refreshData(
			[from, to],
			[from.format("YYYY-MM-DD"), to.format("YYYY-MM-DD")]
		);

		this.getWorkedTimeToday();
	}

	handleSearch = (selectedKeys, key, confirm) => {
		confirm();
		const state = {};
		state[key] = selectedKeys[0].toString();
		this.setState(state);
	};

	handleReset = (clearFilters, key) => {
		clearFilters();
		const state = {};
		state[key] = "";
		this.setState(state);
	};

	refreshData = (date, dateString) => {
		this.setState({
			royalty: null,
			from: dateString[0],
			to: dateString[1]
		});
	};

	getRoyalty = () => {
		const { from, to } = this.state;
		this.setState({ loadingRoyalty: true });
		api.fileExports
			.getRoyalty(from, to)
			.then((blob) => {
				fileSaver.saveAs(blob, `dtmn-royalty-${from}-${to}.csv`);
				this.setState({ loadingRoyalty: false });
				return blob;
			})
			.catch((err) => {
				logger.error(err, "Failed to get royalty data");
				this.setState({ loadingRoyalty: false });
			});
	};

	getWorklog = () => {
		const { from, to } = this.state;
		this.setState({ loadingWorklog: true });
		api.fileExports
			.getWorklog(from, to)
			.then((blob) => {
				fileSaver.saveAs(blob, `dtmn-worklog-${from}-${to}.csv`);
				this.setState({ loadingWorklog: false });
				return blob;
			})
			.catch((err) => {
				logger.error(err, "Failed to get work log");
				this.setState({ loadingWorklog: false });
			});
	};

	getWorkedTimeToday = () => {
		const now = new Date();
		const workedTimeTodayFetched = `${now.getHours()}:${now.getMinutes()}`;

		api.fileExports
			.getWorkedTimeToday()
			.then((workedTimeToday) => {
				if (workedTimeToday) {
					this.setState({
						workedTimeToday,
						workedTimeTodayFetched
					});
				}

				return workedTimeToday;
			})
			.catch((err) => {
				logger.error(err, "Failed to get workedTimeToday");
			});
	};

	render() {
		const {
			from,
			to,
			loadingWorklog,
			workedTimeToday,
			workedTimeTodayFetched
		} = this.state;
		const { user } = this.props;

		const workType = isAdmin(user)
			? "Worked"
			: isNarrator(user)
			? "Recorded"
			: "Proof listened";

		return (
			<>
				<div className="file-exports">
					<Title level={3}>{workType} time today (estimated)</Title>
					<div className="workedtimetoday-container">
						{workType} time today: {workedTimeToday}.
						<span className="workedtimetoday-fetched">
							Last checked {workedTimeTodayFetched}
						</span>
					</div>
					<Title level={3}>File Exports</Title>
					<Tooltip
						placement="topRight"
						title="Select the date interval you want to export data for."
					>
						<RangePicker
							className="date-picker"
							onChange={this.refreshData}
							defaultValue={[moment(), moment().add(1, "days")]}
							ranges={{
								Today: [moment(), moment().add(1, "days")],
								"This Month": [
									moment().startOf("month"),
									moment().endOf("month").add(1, "days")
								],
								"This Year": [
									moment().startOf("year"),
									moment().endOf("year").add(1, "days")
								]
							}}
						/>
					</Tooltip>

					{from && to !== undefined ? (
						<div className="file-export-worked-time-summary-container">
							<Title level={4}>Remunerable time per day</Title>
							<Alert
								message="Note: The dates reflect the publish date of articles and not necessarily
							the days work was performed"
								type="info"
							/>

							<div className="file-export-worked-time-summary">
								<SummarizedWork to={to} from={from} />
							</div>
						</div>
					) : null}

					<br />

					{from && to ? (
						<Tooltip
							placement="bottomLeft"
							title={`Download report from ${from} to ${to}.`}
						>
							<Button
								type="primary"
								icon={loadingWorklog ? <LoadingOutlined /> : <DollarOutlined />}
								className="report-btn"
								onClick={!loadingWorklog && this.getWorklog}
								disabled={loadingWorklog}
							>
								{loadingWorklog ? "Preparing report" : "Download report"}
							</Button>
						</Tooltip>
					) : (
						<Tooltip
							placement="bottomLeft"
							title="Please select a date interval to enable the button."
						>
							<Button
								type="primary"
								icon={<DollarOutlined />}
								className="report-btn"
								disabled
							>
								Download report
							</Button>
						</Tooltip>
					)}
				</div>
			</>
		);
	}
}

FileExports.propTypes = {
	user: PropTypes.object.isRequired
};

export default connector(FileExports, (props) => ({
	user: props.user
}));
