import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import * as actions from "./actions";
import createStore from "./createStore";
import SubscribeComponent from "./SubscribeComponent";

const initialState = {
	publishers: null,
	users: null,
	user: {},
	path: "/",
	isLoading: {
		users: false,
		publishers: false,
		teachingMaterials: false,
		articles: false,
		guides:false
	},
	authState: null
};

const mapStateToProps = (state, buildProps) => {
	const {
		publishers,
		users,
		user,
		action = {},
		path,
		isLoading,
		authState,
		guides
	} = state;

	return buildProps({
		publishers,
		users,
		user,
		action,
		path,
		isLoading,
		authState,
		guides
	});
};

const mapDispatchToProps = (dispatch, buildProps) => {
	return {
		action: bindActionCreators(
			buildProps({ action: actions }).action || {},
			dispatch
		)
	};
};

export const history = createBrowserHistory();

export const store = createStore(initialState);

export const connector = (component, buildProps = (state) => state) => {
	return connect(
		(state) => mapStateToProps(state, buildProps),
		(dispatch) => mapDispatchToProps(dispatch, buildProps)
	)(SubscribeComponent(component));
};

export default store;
