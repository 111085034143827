export default (request) => ({
	get(publisher) {
		return request({
			path: `publisher/${publisher}/teachingMaterial`,
			method: "get"
		});
	},
	updateDefaultNarrator(publisher, teachingMaterial, user, overwrite) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/narrator`,
			data: { overwrite },
			method: "patch",
			body: user
		});
	},
	updateDefaultProofListener(publisher, teachingMaterial, user, overwrite) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/proofListener`,
			data: { overwrite },
			method: "patch",
			body: user
		});
	},
	enable(publisher, teachingMaterial) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/enable`,
			method: "patch"
		});
	},
	disable(publisher, teachingMaterial) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/disable`,
			method: "patch"
		});
	},
	disableAllNewArticles() {
		return request({
			path: `disableAllNewArticles`,
			method: "patch"
		});
	},
	redetect(publisher, teachingMaterial) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/redetect`,
			method: "patch"
		});
	},
	updateDefaultGuideNarrator(publisher, teachingMaterial, language, user, overwrite) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/guides/${language}/narrator`,
			data: {overwrite},
			method: "patch",
			body: user
		});
	},
	updateDefaultGuideProofListener(publisher, teachingMaterial, language, user, overwrite) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/guides/${language}/proofListener`,
			data: {overwrite},
			method: "patch",
			body: user
		});
	},
});
