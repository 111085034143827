const usersReducer = (state = [], action) => {
	switch (action.type) {
		case "SET_USERS":
			return action.users || state;
		case "CLEAR_DATA":
			return null;
		default:
			return state;
	}
};
export default usersReducer;
