import Requestor from "./requestor";
import publishers from "./publishers";
import teachingMaterials from "./teachingMaterials";
import userGroups from "./userGroups";
import articles from "./articles";
import guides from "./guides";
import fileExports from "./fileExports";
import search from "./search";

const request = Requestor({
	apiUrl: process.env.REACT_APP_ILT_API_URL || null
});

const api = {
	publishers: publishers(request),
	teachingMaterials: teachingMaterials(request),
	userGroups: userGroups(request),
	articles: articles(request),
	fileExports: fileExports(request),
	search: search(request),
	guides:guides(request)
};
export default api;
