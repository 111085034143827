import api from "../../api";
import errorHandler from "./errorHandler";
import * as logger from "../../helpers/logger";

export const getUsersIfNeeded = cb => {
	return async (dispatch, getState) => {
		const users = getState().users || null;
		if (!users) {
			return dispatch(getUsers(cb));
		}
		return users;
	};
};

export const getUsers = cb => {
	return async dispatch => {
		dispatch({
			type: "FETCHING_USERS"
		});
		return api.userGroups
			.get()
			.then(data => {
				if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
				dispatch({
					type: "SET_USERS",
					users: data
				});
				if (typeof cb === "function") cb(data);
				return data;
			})
			.catch(err => {
				logger.error(err, "Failed to get users");
				return Promise.reject(err);
			});
	};
};
