import api from "../../api";
import errorHandler from "./errorHandler";
import * as logger from "../../helpers/logger";

export const getGuidesIfNeeded = (publisherId, teachingMaterialId, cb) => {
    return async (dispatch, getState) => {
        const guides = getState().guides || [];

        if (guides.length === 0) {
            return dispatch(getGuides(publisherId, teachingMaterialId, cb));
        }
        return guides;
    };
};

export const getGuides = (publisher, teachingMaterial, cb) => {
    return async (dispatch) => {
        dispatch({
            type: "FETCHING_GUIDES"
        });
        return api.guides
            .get(publisher, teachingMaterial)
            .then((data) => {
                if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
                dispatch({
                    type: "SET_GUIDES",
                    publisher,
                    teachingMaterial,
                    data
                });
                if (typeof cb === "function") cb(data);
                return data;
            })
            .catch((err) => {
                logger.error(err, "Failed to get guides");
                return Promise.reject(err);
            });
    };
};

export const getAllGuidesLanguages = () => {
    return async (dispatch) => {
        return api.guides
            .getAllGuidesLanguages()
            .then((data) => {
                if (errorHandler(dispatch, data)) return Promise.reject(data.errors);
                return data;
            })
            .catch((err) => {
                logger.error(err, "Failed to get all guides languages.");
                return Promise.reject(err);
            });
    };
};