import React from "react";
import Highlighter from "react-highlight-words";
import Button from "antd/lib/button";
import {Popconfirm} from "antd";
import * as constants from "../../constants";
import {getNarrators, getProofListeners, splitUsername} from "../../helpers/authHelpers";
import SelectUserForm from "../../forms/selectUserForm";
import GetColumnSearchProps from "../columnSearch"

export const languageColumn = (component) => {
    return {
        title: "Language",
        key: "language",
        dataIndex: "Language",
        sorter: (a, b) => {
            return a.Language && a.Language.localeCompare(b.Language);
        },
        render: (Id, row) =>
            component.state.searchedColumn === "language" ? (
                <Highlighter
                    highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
                    searchWords={[component.state.searchText]}
                    autoEscape
                    textToHighlight={row.Language}
                />
            ) : (
                row.Language
            ),
        sortDirections: ["descend", "ascend"],
        ...GetColumnSearchProps("Language", undefined, component.setSearchText, component.setSearchColumn),
    };
};

export const titleColumn = (component) => {
    return {
        title: "Article Title",
        key: "title",
        dataIndex: "Title",
        width: 450,
        render: (id, row) =>
            <a
                href={component.getGuideArticle(row).Url}
                target="_blank"
                rel="noopener noreferrer"
            >
                {component.getGuideArticle(row).Title}
            </a>,
    };
};

/**
 *
 * @param users
 * @param guides
 * @param {Guides} component
 * @returns {{dataIndex: string, filteredValue: (string[]|undefined), width: number, onFilter: (function(*, *): boolean), filters: {text: *, value: *}[], title: string, render: (function(*, *)), key: string}}
 */
export const selectNarratorColumn = (users, guides, component) => {
    const selectedFilters = component.state.filters.narrator;
    const existingNarrators = [];

    if(guides){
        guides.forEach((article) => {
            getNarrators(users).forEach((user) => {
                if (user.Id === article.NarratorId && !existingNarrators.includes(user)) {
                    existingNarrators.push(user);
                }
            });
        });
    }


    return {
        title: "Narrator",
        key: "narrator",
        dataIndex: "Narrator",
        filters: existingNarrators
            .map(function (user) {
                return {
                    value: user.Id,
                    text: splitUsername(user.Name)
                };
            })
            .concat([
                {
                    value: constants.GUID_EMPTY,
                    text: "Unassigned"
                }
            ]),
        onFilter: (value, record) => record.NarratorId === value,
        filteredValue: selectedFilters ? selectedFilters.split(",") : undefined,
        render: (id, row) => (
            <SelectUserForm
                users={component.props.users}
                userType={"narrator-" + row.Language.toLowerCase()}
                row={row}
                onChange={component.onChangeNarratorOrProofListener}
                match={component.props.match}
                action={component.props.action}
                showConfirmPopup={false}
            />
        ),
        width: 200
    };
};
/**
 *
 * @param users
 * @param guides
 * @param {Guides} component
 * @returns {{dataIndex: string, filteredValue: (*|undefined), width: number, onFilter: (function(*, *): boolean), filters: {text: *, value: *}[], title: string, render: (function(*, *)), key: string}}
 */
export const selectProofListenerColumn = (users, guides, component) => {
    const selectedFilters = component.state.filters.proof_listener;
    const existingProofListeners = [];

    if(guides) {
        guides.forEach((article) => {
            getProofListeners(users).forEach((user) => {
                if (
                    user.Id === article.ProofListenerId &&
                    !existingProofListeners.includes(user)
                ) {
                    existingProofListeners.push(user);
                }
            });
        });
    }
    return {
        title: "Proof Listener",
        key: "proof_listener",
        dataIndex: "Proof_Listener",
        filters: existingProofListeners
            .map(function (user) {
                return {
                    value: user.Id,
                    text: splitUsername(user.Name)
                };
            })
            .concat([
                {
                    value: constants.GUID_EMPTY,
                    text: "Unassigned"
                }
            ]),
        onFilter: (value, record) => record.ProofListenerId === value,
        filteredValue: selectedFilters ? selectedFilters.split(",") : undefined,
        render: (id, row) => (
            <SelectUserForm
                users={component.props.users}
                userType={"prooflistener-" + row.Language.toLowerCase()}
                row={row}
                onChange={component.onChangeNarratorOrProofListener}
                match={component.props.match}
                action={component.props.action}
                showConfirmPopup={false}
            />
        ),
        width: 200
    };
};
/**
 *
 * @param {Guides} component
 * @param article
 * @returns {{dataIndex: string, width: number, title: string, render: (function(*, *=): []), key: string}}
 */
export const enableDisableColumn = (component) => {
    return {
        title: "",
        key: "enabled_state",
        dataIndex: "Enabled_State",
        render: (id, row) => {
            const result = [];
            if (row.State !== constants.GUIDE_STATE.Disabled) {
                result.push(
                    <Button
                        className="guides_custom_button_width"
                        type="danger"
                        key={row.id + "-disable"}
                        block
                        loading={
                            component.props.isLoading.article === row.ArticleId &&
                            component.props.isLoading.disable
                        }
                        onClick={() => component.onStateBtnClick(component.props, row)}
                    >
                        Disable{" "}
                    </Button>
                );
            } else {
                result.push(
                    <span key={row.id + "-enable"}>
                        <Popconfirm
                            title={<div><p>Are you sure you want to enable this guide?</p></div>}
                            onConfirm={() => component.onStateBtnClick(component.props, row)}
                        >
                            <Button
                                className="guides_custom_button_width"
                                type="default"
                                block
                                loading={
                                    component.props.isLoading.article === row.ArticleId &&
                                    component.props.isLoading.enable
                                }
                            >
                            Enable
                            </Button>
                        </Popconfirm>
                    </span>
                );
            }

            return result;
        },
        width: 120
    };
};
export const articleGotChangedColumn = (component) => {
    return {
        title: "Article got changes",
        key: "articleGotChanged",
        dataIndex: "ArticleVersion",
        render: (id, row) =>
            row.ArticleVersion !== component.getGuideArticle(row).Version ? (
                <b style={{color: 'red'}}>Yes</b>
            ) : (
                <span>No</span>
            ),
        width: 120
    };
};


