import React, { Fragment } from "react";
import "./SearchResults.css";
import * as constants from "../../constants";
import * as logger from "../../helpers/logger";

const articleContent = (content) => {
	return { __html: content };
};

const SearchResults = ({ data }) => {
	if (!data || data.errors) {
		logger.error(data.errors);
		return <div></div>;
	}
	const Articles = data.filter(
		(p) => p.ArticleId && p.ArticleId !== constants.GUID_EMPTY
	);
	const TeachingMaterials = data.filter(
		(p) => p.TeachingMaterialId && p.ArticleId === constants.GUID_EMPTY
	);
	return (
		<div className="search-results-container">
			{Articles && Articles.length ? (
				<Fragment>
					<h3 className="search-result-category-heading">Articles</h3>

					<ul className="search-result--article-list">
						{Articles.map((p) => (
							<li key={p.ArticleId}>
								<a
									className="search-result-item-link"
									href={
										"/publishers/" +
										p.Publisher +
										"/" +
										p.TeachingMaterialId +
										"?page=1&filter-id=" +
										p.ArticleId.substr(p.ArticleId.length - 5)
									}
								>
									<h4 className="search-result-item-heading">
										{p.ArticleTitle}
									</h4>
									<div
										className="search-result-item-content"
										dangerouslySetInnerHTML={articleContent(
											p.ArticleContent.replace(/(<([^>]+)>)/gi, " ")
												.replace(p.ArticleTitle, "")
												.replace(/_FORCED_SENTENCE_/g, "")
												.substring(0, 200)
										)}
									/>
								</a>
							</li>
						))}
					</ul>
				</Fragment>
			) : null}

			{TeachingMaterials && TeachingMaterials.length ? (
				<Fragment>
					<h3 className="search-result-category-heading">Teaching materials</h3>

					<ul className="search-result--teaching-list">
						{TeachingMaterials.map((p) => (
							<li key={p.TeachingMaterialId}>
								<a
									href={
										"/publishers/" + p.Publisher + "/" + p.TeachingMaterialId
									}
								>
									<h4>
										{p.TeachingMaterialTitle} - {p.Publisher}
									</h4>
								</a>
							</li>
						))}
					</ul>
				</Fragment>
			) : null}

			{!TeachingMaterials.length && !Articles.length ? (
				<Fragment>
					<h3 className="search-result-category-heading">No results found</h3>
					<p className="search-result-no-result">
						Try something else. You can search for ID:s, titles or URL
					</p>
				</Fragment>
			) : null}
		</div>
	);
};

export default SearchResults;
