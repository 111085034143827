export default (request) => ({
	get(publisher, teachingMaterial) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}`,
			method: "get"
		});
	},
	publish(publisher, teachingMaterial, article) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/articles/${article}/publish`,
			method: "patch"
		});
	},
	unpublish(publisher, teachingMaterial, article) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/articles/${article}/unpublish`,
			method: "patch"
		});
	},
	reset(publisher, teachingMaterial, article) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/articles/${article}/reset`,
			method: "patch"
		});
	},
	reprocess(publisher, teachingMaterial, article) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/articles/${article}/reprocess`,
			method: "patch"
		});
	},
	redetect(publisher, teachingMaterial, article) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/articles/${article}/redetect`,
			method: "patch"
		});
	},
	enable(publisher, teachingMaterial, article) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/articles/${article}/enable`,
			method: "patch"
		});
	},
	disable(publisher, teachingMaterial, article) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/articles/${article}/disable`,
			method: "patch"
		});
	},
	disableGuides(publisher, teachingMaterial, article) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/articles/${article}/disableGuides`,
			method: "patch"
		});
	},
	enableGuides(publisher, teachingMaterial, article) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/articles/${article}/enableGuides`,
			method: "patch"
		});
	},
	setNarrator(publisher, teachingMaterial, article, narratorId) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/articles/${article}/narrator`,
			method: "patch",
			body: narratorId
		});
	},
	setProofListener(publisher, teachingMaterial, article, proofListenerId) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/articles/${article}/proofListener`,
			method: "patch",
			body: proofListenerId
		});
	},
	setState(publisher, teachingMaterial, article, state) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/articles/${article}/state`,
			method: "patch",
			body: {
				state
			}
		});
	},
	getArticle(publisher, teachingMaterial, article) {
		return request({
			path: `publisher/${publisher}/teachingMaterial/${teachingMaterial}/articles/${article}`,
			method: "get"
		});
	}
});
