import React from "react";
import Tooltip from "antd/lib/tooltip";

import "./index.css";
import PropTypes from "prop-types";

//  Indicator uses color of first positive condition
export default function StatusIndicator(props) {
	const { conditions } = props;
	const rowSettings = conditions.find(condition => !!condition.condition);
	return (
  <Tooltip title={rowSettings.tooltip}>
    <div
      className="__statusIndicator"
      style={{ background: rowSettings.color }}
    />
  </Tooltip>
	);
}

StatusIndicator.propTypes = {
	conditions: PropTypes.arrayOf(PropTypes.object).isRequired
};
