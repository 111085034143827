const publisherReducer = (state = null, action) => {
	switch (action.type) {
		case "SET_PUBLISHERS":
			return action.publishers || state;
		case "SET_TEACHING_MATERIALS":
			return [
				...state.map(publisher =>
					publisher.Id === action.publisher
						? { ...publisher, TeachingMaterials: action.data }
						: publisher
				)
			];
		case "SET_ARTICLES":
			return [
				...state.map((publisher = { TeachingMaterials: null }) =>
					publisher.Id === action.publisher
						? {
								...publisher,
								TeachingMaterials: publisher.TeachingMaterials.map(
									teachingMaterial =>
										teachingMaterial.Id === action.teachingMaterial
											? action.data
											: teachingMaterial
								)
						  }
						: publisher
				)
			];
		case "REMOVE_ARTICLE":
			return [
				...state.map(publisher =>
					publisher.Id === action.publisher
						? {
								...publisher,
								TeachingMaterials: publisher.TeachingMaterials.map(
									teachingMaterial =>
										teachingMaterial.Id === action.teachingMaterialId
											? {
													...teachingMaterial,
													Articles: teachingMaterial.Articles.filter(
														article => article.Id !== action.articleId
													)
											  }
											: teachingMaterial
								)
						  }
						: publisher
				)
			];
		case "SET_ARTICLE":
			return [
				...state.map(publisher =>
					publisher.Id === action.publisher
						? {
								...publisher,
								TeachingMaterials: publisher.TeachingMaterials.map(
									teachingMaterial =>
										teachingMaterial.Id === action.teachingMaterial
											? {
													...teachingMaterial,
													Articles: teachingMaterial.Articles.map(article =>
														article.Id === action.data.Id
															? action.data
															: article
													)
											  }
											: teachingMaterial
								)
						  }
						: publisher
				)
			];
		case "CLEAR_DATA":
			return null;
		case "SET_PROOF_LISTENER_SUCCESS":
		case "SET_NARRATOR_SUCCESS":
		case "SET_DISABLE_STATE_SUCCESS":
		case "PUBLISH_ARTICLE_SUCCESS":
		case "UNPUBLISH_ARTICLE_SUCCESS":
		case "UNPLUBLISH_ARTICLE_SUCCESS":
		case "RESET_ARTICLE_SUCCESS":
		case "REPROCESS_ARTICLE_SUCCESS":
		case "UPDATE_DEFAULT_NARRATOR_SUCCESS":
		case "UPDATE_DEFAULT_PROOF_LISTENER_REQUEST":
		default:
			return state;
	}
};
export default publisherReducer;
