const userReducer = (state = {}, action) => {
	switch (action.type) {
		case "SET_USER":
			return action.data || state;
		case "CLEAR_DATA":
			return {};
		default:
			return state;
	}
};
export default userReducer;
